import { logout } from "./slices/auth";
import { store } from "./store";
import { generateUUID } from "./uuid";

import { posthogClient } from "../config/posthog";
import { loginRoute } from "../routes/login/route";

export const getAuthToken = () => {
  const localToken = localStorage.getItem("token");

  if (localToken != null) {
    return `Bearer ${localToken}`;
  }

  return null;
};

// Datadog trace ID, unique per document load
export const traceId = generateUUID();

export async function smartFetch(
  url: string,
  requestOpts: RequestInit = {},
  askAuth?: boolean,
  opts: Record<string, string | null> = {},
  trials = 3,
) {
  const requiresAuth = url.includes("private") || url.includes("upload");
  const defaultHeaders: Record<string, string> = {
    "Content-Type": "application/json",
    "x-datadog-trace-id": traceId,
    "x-datadog-parent-id": "0", // 0 indicates this is the root span
    ...(requestOpts.headers as Record<string, string>),
  };
  requestOpts.mode = "cors";
  requestOpts.credentials = "include";

  if ((!opts.token && requiresAuth) || askAuth) {
    const localToken = getAuthToken();

    if (localToken == null) {
      throw new Error(
        "Attempt to access private route without an auth token is forbidden.",
      );
    }

    defaultHeaders.Authorization = localToken;
  } else if (opts.token) {
    defaultHeaders.Authorization = `Bearer ${opts.token}`;
  }

  requestOpts.headers = defaultHeaders;

  // Make the request and retry if it fails with a network error
  let response: Response;
  try {
    response = await fetch(url, requestOpts);
    if (!response.status && requestOpts.method === "GET" && trials > 0) {
      console.warn("Failed to fetch; retrying GET request");
      return smartFetch(url, requestOpts, askAuth, opts, trials - 1);
    }
  } catch (e) {
    if (
      (e as Error).message.indexOf("fetch") >= 0 &&
      requestOpts.method === "GET" &&
      trials > 0
    ) {
      console.warn("Failed to fetch; retrying GET request");
      return smartFetch(url, requestOpts, askAuth, opts, trials - 1);
    } else {
      throw e;
    }
  }

  if (!response.ok) {
    // Token expired
    if (response.status === 401) {
      const responseData = (await response.clone().json()) as { error: string };
      if (responseData.error === "Invalid Token") {
        store.dispatch(logout());
        posthogClient.reset();
        window.location.assign(loginRoute.to + "?error=session-expired");
      }
    }

    throw response;
  }

  const refreshedToken = response.headers.get("X-Refreshed-Token");
  if (refreshedToken) {
    const url = new URL(window.location.href);
    url.searchParams.set("refreshedToken", refreshedToken);
    localStorage.removeItem("token");
    window.location.href = url.toString();
    return;
  }

  if (response.status === 204) {
    return null;
  }

  return response.json();
}

export async function downloadAsset(
  url: string,
  fileName?: string,
  analyticsEventName = "downloaded_asset",
  analyticsEventProperties: Record<string, boolean | number | string> = {},
) {
  const startTime = Date.now();
  // Fetch the file instead of just downloading the file directly so that the
  // appropriate headers are set for AssetShield.
  const response = await fetch(url);
  const fileType = response.headers.get("Content-Type");

  // Extract the filename from the Content-Disposition header if available, and
  // from the URL if not
  const contentDisposition = response.headers.get("Content-Disposition");
  const serverFileName = contentDisposition?.match(/filename="(.+)"/i)?.[1];
  fileName ||=
    serverFileName ||
    decodeURIComponent(new URL(url).pathname.split("/").pop() || "download");

  if (!response.ok) {
    posthogClient.capture(analyticsEventName + "_error", {
      assetUrl: url,
      assetName: fileName,
      fileType,
      durationSeconds: (Date.now() - startTime) / 1000,
      statusCode: response.status,
      statusText: response.statusText,
      responseText: await response.text(),
      ...analyticsEventProperties,
    });
    throw new Error("Failed to download asset");
  }

  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.download = fileName;
  link.href = blobUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  posthogClient.capture(analyticsEventName, {
    assetUrl: url,
    assetName: fileName,
    fileType,
    fileSizeMB: blob.size / 1024 / 1024,
    durationSeconds: (Date.now() - startTime) / 1000,
    ...analyticsEventProperties,
  });

  // Ensure the download starts before we release the memory
  setTimeout(() => URL.revokeObjectURL(url), 500);
}

export const handleApiError = async (e: unknown) => {
  if (e instanceof Response) {
    const error = e as Response;
    const errorData = (await error.clone().json()) as {
      message?: string;
    };
    const errorMessage = errorData.message || "An unknown error occurred";

    throw new Error(errorMessage);
  } else {
    console.error("Error is not a Response:", e);
    throw e;
  }
};

export const errorMessage = async (error: unknown): Promise<string> => {
  if (error instanceof Response) {
    try {
      const errorData = (await error.clone().json()) as {
        message?: string;
      };
      return typeof errorData.message === "string"
        ? errorData.message
        : JSON.stringify(errorData);
    } catch {
      // If JSON parsing fails, try to handle XML (e.g. from S3 errors)
      const text = await error.clone().text();
      const messageMatch = text.match(/<Message>(.+?)<\/Message>/)?.[1];
      return messageMatch ?? text;
    }
  } else if (error instanceof Error) {
    if (error.message === "Failed to fetch") {
      return "Either the server had a hiccup or there was a problem with your internet connection. Please try again.";
    }
    if (error.cause instanceof Error) {
      return `${error.message} (caused by: ${errorMessage(error.cause)})`;
    }
    return error.message;
  } else if (!String(error).startsWith("[object ")) {
    return String(error);
  }
  return "An unknown error occurred";
};

import { Checkbox, Text, TextField } from "@radix-ui/themes";

import { type KnowledgeDataModelProperty } from "../../../../../utils/parsers/knowledge-db";

type DynamicInputProps = KnowledgeDataModelProperty & {
  baseTestId: string;
  value?: string | number | boolean | null;
};

export function DynamicInput(props: DynamicInputProps) {
  const { baseTestId, name, required, type, value, _id } = props;

  // This is used because some OIDs can start with a number, which is not a
  // valid id property in HTML.
  const fieldIdentifier = `field-${_id}`;

  function renderName() {
    if (required)
      return (
        <>
          <Text>{name}</Text>
          <Text color="red">*</Text>
        </>
      );

    return name;
  }

  switch (type) {
    case "checkbox":
      return (
        <>
          <label htmlFor={fieldIdentifier}>
            <Text size="2">{renderName()}</Text>
          </label>
          <Checkbox
            size="2"
            name={fieldIdentifier}
            id={fieldIdentifier}
            defaultChecked={value as boolean}
            className="justify-self-start"
            data-testid={`${baseTestId}-${name}`}
          />
        </>
      );
    case "number":
      return (
        <>
          <label htmlFor={fieldIdentifier}>
            <Text size="2">{renderName()}</Text>
          </label>
          <TextField.Root
            size="2"
            type="number"
            placeholder="Enter a number"
            name={fieldIdentifier}
            id={fieldIdentifier}
            required={required}
            defaultValue={value as string | number}
            data-testid={`${baseTestId}-${name}`}
          />
        </>
      );
    case "date":
    case "text":
    case "url":
      //case "file":
      return (
        <>
          <label htmlFor={fieldIdentifier}>
            <Text size="2">{renderName()}</Text>
          </label>
          <TextField.Root
            size="2"
            type={type}
            placeholder={`Enter ${type}`}
            name={fieldIdentifier}
            id={fieldIdentifier}
            required={required}
            defaultValue={value as string | number}
            data-testid={`${baseTestId}-${name}`}
          />
        </>
      );
    case "single-select":
    case "multi-select":
      return null;
  }
}

import { TicketCommentDisposition } from "@mg/schemas/src/commons";
import { Grid, Heading } from "@radix-ui/themes";
import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import {
  debounce,
  getSnapshot,
  loadSnapshot,
  type TLShapeId,
  type Vec,
} from "tldraw";

import DocumentPageThumbnails from "./DocumentPageThumbanils";
import PunttTldraw from "./PunttTldraw";

import PendingProgress from "../../../../../../components/PendingProgressBar";
import { useAnalytics } from "../../../../../../utils/analytics";
import { isNil } from "../../../../../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../../../../../utils/hooks";
import { useUpdateRevisionMutation } from "../../../../../../utils/queries/projects";
import { setInitialShapes } from "../../../../../../utils/slices/ticket";
import { handleUpdateCameraBounds } from "../../../../../../utils/tldraw/camera";
import { placeComments } from "../../../../../../utils/tldraw/comments";
import {
  filterCommentsFromStore,
  getFilteredImageShapes,
  initializeCanvas,
  mapShapesToThumbnails,
} from "../../../../../../utils/tldraw/handlers";
import { generateUUID } from "../../../../../../utils/uuid";
import { useYjsStore } from "../../../../components/useYjsStore";
import { useEditorAutoSave } from "../../../components/useEditorAutoSave";
import { ticketRoute } from "../../route";
import { useTicket } from "../../view";

type AssetCanvasProps = {
  mode: "canvas" | "doc";
};

/**
 * The main component used to render assets and comments on a canvas. The
 * purpose of this component is to wrap which third-party library we're using for
 * infinite canvas functionality.
 *
 * For now, we're using TLDraw.
 */
export default function AssetCanvas(props: AssetCanvasProps) {
  const { mode } = props;
  const { editor } = useTicket();
  const { ticketId } = ticketRoute.useParams();

  // Slices
  const dispatch = useAppDispatch();
  const reversedVersionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );
  const versions = useAppSelector((state) => state.ticket.revisions);
  const selectedVersion = versions[reversedVersionIndex!];
  const comments = useAppSelector((state) => state.ticket.comments);
  const showResolvedComments = useAppSelector(
    (state) => state.ui.showResolvedComments,
  );
  const showDismissedComments = useAppSelector(
    (state) => state.ui.showDismissedComments,
  );

  // Analytics
  const analytics = useAnalytics("Revisions"); // Use the same descriptor as before the rewrite

  // Queries and mutations
  const updateRevisionMutation = useUpdateRevisionMutation();

  // Local state
  const [selectedDocPageShape, setSelectedDocPageShape] = useState<TLShapeId>();
  const [didCreateBoard, setDidCreateBoard] = useState(false);
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(
    // Enabled for canvas mode where shapes are null by default since we will
    // need to create the board and call the mutation to save the board to the
    // database
    mode === "canvas" && isNil(selectedVersion?.shapes),
  );
  const [readyForComments, setReadyForComments] = useState(false);

  // Used to handle canvas scrolling when in doc mode to select the respective
  // thumbnail in the sidebar.
  // TODO: move this to its own file.
  const checkVisibility = useCallback(
    (currentPagePoint: Vec) => {
      if (isNil(editor)) return;

      const shapesAtPoint = editor.getShapesAtPoint(currentPagePoint);
      const currentPageShapes = getFilteredImageShapes(
        editor.getCurrentPageShapes(),
      );

      if (isNil(shapesAtPoint) || !shapesAtPoint.length) {
        // if we don't have a shape at the point, get the nearest one
        const nearestShape = currentPageShapes.reduce(
          (nearest, shape) => {
            const shapeCenter = {
              x: shape.x + shape.props.w / 2,
              y: shape.y + shape.props.h / 2,
            };
            const distance = Math.sqrt(
              Math.pow(shapeCenter.x - currentPagePoint.x, 2) +
                Math.pow(shapeCenter.y - currentPagePoint.y, 2),
            );

            if (!nearest || distance < nearest.distance) {
              return { shape, distance };
            }
            return nearest;
          },
          null as { shape: any; distance: number } | null,
        );

        if (nearestShape) {
          // TODO: this is a pretty big performance hit
          setSelectedDocPageShape(nearestShape.shape.id);
          return;
        }
      }

      const found = currentPageShapes.find(
        (shape) => shape.id === shapesAtPoint[0].id,
      );

      if (isNil(found)) return;

      // TODO: this is a pretty big performance hit
      setSelectedDocPageShape(found.id);
    },
    [editor],
  );

  // The debounced version of `checkVisibility`
  const debouncedCheckVisibility = useCallback(debounce(checkVisibility, 100), [
    checkVisibility,
  ]);

  // Connect to Yjs socket
  const store = useYjsStore({ roomId: selectedVersion?._id });

  // Set up auto-save callback
  const autosaveCallback = useCallback(async () => {
    if (
      store.status === "loading" ||
      isNil(store.store) ||
      isNil(selectedVersion) ||
      selectedVersion.isPending
    ) {
      return null;
    }

    const snapshot = filterCommentsFromStore(getSnapshot(store.store).document);

    updateRevisionMutation.mutateAsync({
      boardId: selectedVersion._id,
      ticketId,
      payload: {
        shapes: snapshot,
      },
    });
  }, [selectedVersion, store.status, store.store]);

  useEditorAutoSave(editor, autosaveCallback, autoSaveEnabled);

  // Effect chain

  useEffect(() => {
    // Once we have an editor and a store, we want to load the initial shapes
    // onto it. We can make 2 assumptions on how to load a snapshot:
    //   - If we connected to a room and someone is already in it, we already
    //     have all the shapes and comments we need in the connected store.
    //   - If we connected to a room and no one else is in it, we can just load
    //     the shapes we have locally.
    //
    // This does not consider the case where the user simply cannot connect to
    // the remote server. In that case, we want to also watch for sync-offline,
    // offline, etc. There are many reasons why we should just load the local
    // copy of the shapes instead of loading what's on the connected socket.
    //
    // As a final step in any board connection, we enable the editor auto save.
    // This is done post-connection and board creation since we don't need to
    // auto save the board immediately upon connecting to a room, or by loading
    // the snapshot we just got back from the API. The only instance we want to
    // auto save is when we've created a non-doc-mode board, or added new
    // shapes.

    if (isNil(store.store) || isNil(editor) || isNil(selectedVersion)) return;

    if (store.status === "synced-remote") {
      const collaborators = editor.getCollaboratorsOnCurrentPage();

      // If anyone else is already on the board, the snapshot will load
      // automatically and we don't need to do anything.
      if (collaborators.length) {
        if (mode === "doc") {
          handleUpdateCameraBounds(
            store.store.getStoreSnapshot().store,
            editor,
          );
        } else {
          editor.zoomToFit();
        }

        setReadyForComments(true);
        setAutoSaveEnabled(true);
        return;
      }

      // As long as we have shapes and we didn't just create a board, go ahead
      // and load the shapes we have on the ticket.
      if (!isNil(selectedVersion.shapes)) {
        const { shapes } = selectedVersion;
        loadSnapshot(store.store, shapes);

        if (mode === "doc") {
          handleUpdateCameraBounds(selectedVersion.shapes.store, editor);
        } else {
          editor.zoomToFit();
        }

        setReadyForComments(true);
      }

      // At this point, shapes will be `null`, we could be in canvas mode, and we
      // should be creating the board for the first time.
      else if (mode === "canvas" && !didCreateBoard) {
        // There's a good chance that if we don't have shapes and we're on
        // canvas mode, the user has either just made the ticket and
        // consequently the first version as well, or the user has just
        // created a new version. Regardless, if another user is already on
        // the canvas, all new presences will read the shapes from the
        // connected store, otherwise we are safe to make the new shapes.
        setDidCreateBoard(true);

        dispatch(
          setInitialShapes(
            initializeCanvas(editor, selectedVersion.reviewFiles ?? []),
          ),
        );
      }
    }

    setAutoSaveEnabled(true);
  }, [
    didCreateBoard,
    editor,
    selectedVersion?.reviewFiles,
    selectedVersion?.shapes,
    store.store,
    store.status,
  ]);

  useEffect(() => {
    // Once we've loaded any board, we want to place comments on it. We do this
    // in a separate effect from the board loading, otherwise the camera bounds
    // will reset in doc mode, forcing the camera back to the top whenever the
    // user adds a comment.
    if (
      !readyForComments ||
      isNil(editor) ||
      isNil(store.store) ||
      isNil(selectedVersion)
    ) {
      return;
    }

    if (store.status === "synced-remote") {
      const filteredComments = comments.filter(
        (comment) =>
          (showResolvedComments ||
            comment.disposition !== TicketCommentDisposition.RESOLVED) &&
          (showDismissedComments ||
            comment.disposition !== TicketCommentDisposition.DISMISSED),
      );

      placeComments(
        store.store.getStoreSnapshot(),
        editor,
        filteredComments,
        selectedVersion._id,
      );
    }
  }, [
    comments,
    editor,
    readyForComments,
    selectedVersion?._id,
    showDismissedComments,
    showResolvedComments,
    store.status,
    store.store,
  ]);

  useEffect(() => {
    // When we're in doc mode, we want to bind the sidebar to the canvas such
    // that when the user scrolls down in the canvas, we can dynamically update
    // the selected doc thumbnail. This is intended to stay loosely coupled by
    // only setting the selectedDocPageShape.
    if (isNil(editor) || mode !== "doc") return;

    function handleEditorEvent() {
      if (isNil(editor)) return;

      const { currentPagePoint } = editor.inputs;
      debouncedCheckVisibility(currentPagePoint);
    }

    editor.addListener("event", handleEditorEvent);

    return () => {
      editor.removeListener("event", handleEditorEvent);
    };
  }, [editor, mode]);

  useEffect(() => {
    // If for any reason we changed versions, we also want to set
    // `didCreateBoard` back to false in case we need to create another one.
    setDidCreateBoard(false);
  }, [selectedVersion?._id]);

  /////
  // No hooks beyond this point
  /////

  function handleDocumentThumbnailClick(shapeId: TLShapeId, index: number) {
    if (isNil(editor)) return;

    const shape = editor.getShape(shapeId);

    if (isNil(shape)) return;

    setSelectedDocPageShape(shapeId);

    const TOP_PADDING = 10;

    editor.setCamera(
      {
        x: shape.x,
        y: shape.y * -1 + TOP_PADDING,
        z: editor.getCamera().z,
      },
      {
        animation: {
          duration: 300,
        },
      },
    );

    analytics.capture("clicked_thumbnail", {
      shapeId,
      page: index + 1,
    });
  }

  function renderCanvasContents() {
    if (isNil(selectedVersion)) {
      return (
        <Grid align="center" justify="center">
          <Heading size="8" align="center" color="gray">
            Upload your first
            <br />
            design for review
          </Heading>
        </Grid>
      );
    }
    const { shapes, thumbnails, isPending } = selectedVersion;

    if (mode === "doc") {
      if (isPending) {
        return (
          <>
            <DocumentPageThumbnails
              pages={Array.from({ length: 5 }).map(() => ({
                shapeId: generateUUID(),
                thumbnail: undefined,
              }))}
              onPageClick={() => {
                // do nothing while loading
              }}
              loading
            />

            <PendingProgress />
          </>
        );
      }

      const sortedThumbnails = mapShapesToThumbnails(shapes, thumbnails);

      return (
        <>
          <DocumentPageThumbnails
            pages={sortedThumbnails}
            onPageClick={handleDocumentThumbnailClick}
            value={selectedDocPageShape}
          />

          <PunttTldraw mode={mode} store={store} />
        </>
      );
    }

    if (isPending) {
      return (
        <>
          <PendingProgress />
        </>
      );
    }

    return <PunttTldraw mode={mode} store={store} />;
  }

  return (
    <Grid
      className={cx({
        "grid-cols-[auto_1fr] overflow-hidden": mode === "doc",
        "grid-cols-1": mode === "canvas",
      })}
      data-testid="asset-canvas"
    >
      {renderCanvasContents()}
    </Grid>
  );
}

import { DotsSixVertical, Plus, Trash } from "@phosphor-icons/react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Flex,
  Grid,
  IconButton,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useEffect, useState, type FormEventHandler, forwardRef } from "react";
import {
  DragDropContext,
  Draggable,
  type DraggableProvidedDraggableProps,
  type DraggableProvidedDragHandleProps,
  Droppable,
} from "react-beautiful-dnd";

import { isNil } from "../../../../../utils/fp";
import {
  type KnowledgeDataModelProperty,
  type CreateKnowledgeDataModelRequest,
  type KnowledgeDataModel,
  KnowledgeDataModelPropertyType,
} from "../../../../../utils/parsers/knowledge-db";
import { type BaseDialogProps } from "../../../../../utils/types/dialog";

type RecordTypeDialogProps = BaseDialogProps &
  (
    | {
        type: "create";
        onSubmit(model: CreateKnowledgeDataModelRequest): void;
      }
    | {
        type: "edit";
        initialData: KnowledgeDataModel;
        onSubmit(model: KnowledgeDataModel): void;
      }
  );

const INITIAL_FORM_STATE: CreateKnowledgeDataModelRequest = {
  name: "",
  properties: [],
  isSystem: false,
};

export default function RecordTypeDialog(props: RecordTypeDialogProps) {
  const { isSubmitting, open, onOpenChange, onSubmit, type } = props;
  const BASE_TESTID = `${type}-record-type-dialog`;

  const [formState, setFormState] = useState<
    CreateKnowledgeDataModelRequest | KnowledgeDataModel
  >(() => {
    if (type === "edit") return props.initialData;

    return INITIAL_FORM_STATE;
  });

  useEffect(() => {
    if (!open) {
      if (type === "create") {
        setFormState(INITIAL_FORM_STATE);
      } else if (type === "edit") {
        setFormState(props.initialData);
      }
    }
  }, [open, type === "edit" && props.initialData]);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    if (type === "create") {
      onSubmit(formState);
    } else if (type === "edit") {
      onSubmit(formState as KnowledgeDataModel);
    }
  };

  function handleEditName(name: string) {
    setFormState((prev) => ({
      ...prev,
      name,
    }));
  }

  function handleAddProperty() {
    setFormState((prev) => ({
      ...prev,
      properties: [
        ...prev.properties,
        {
          name: "",
          type: "text",
          required: false,
          isSystem: false,
        },
      ],
    }));
  }

  function handleEditProperty(
    index: number,
    key: keyof CreateKnowledgeDataModelRequest["properties"][number],
    value: CreateKnowledgeDataModelRequest["properties"][number][typeof key],
  ) {
    setFormState((prev) => ({
      ...prev,
      properties: prev.properties.toSpliced(index, 1, {
        ...prev.properties[index],
        [key]: value,
      }),
    }));
  }

  function handleRemoveProperty(index: number) {
    setFormState((prev) => ({
      ...prev,
      properties: prev.properties.toSpliced(index, 1),
    }));
  }

  function handleDragEnd(result: any) {
    if (!result.destination) return;

    setFormState((prev) => {
      const properties = [...prev.properties];
      const [reorderedItem] = properties.splice(result.source.index, 1);
      properties.splice(result.destination.index, 0, reorderedItem);
      return { ...prev, properties };
    });
  }

  return (
    <Dialog.Root
      open={open}
      onOpenChange={onOpenChange}
      data-testid={BASE_TESTID}
    >
      <Dialog.Content
        size="2"
        width="500px"
        className="grid gap-4"
        data-testid={`${BASE_TESTID}-content`}
      >
        <Box>
          <Dialog.Title data-testid={`${BASE_TESTID}-title`}>
            <span className="capitalize">{type}</span> Record Type
          </Dialog.Title>

          <Dialog.Description
            size="2"
            data-testid={`${BASE_TESTID}-description`}
          >
            This will {type === "create" ? "create a new" : "edit an existing"}{" "}
            record type tab on the Knowledge Database page.
          </Dialog.Description>
        </Box>

        <form
          onSubmit={handleSubmit}
          className="grid gap-3"
          data-testid={`${BASE_TESTID}-form-area`}
        >
          <Grid
            align="center"
            justify="start"
            gap="4"
            data-testid={`${BASE_TESTID}-form`}
          >
            <Text size="2" weight="medium">
              Name
            </Text>

            <TextField.Root
              value={formState.name}
              onChange={({ target }) => handleEditName(target.value)}
              onInput={({ target }) => {
                const typedTarget = target as HTMLInputElement;

                if (!typedTarget.value.trim().length) {
                  typedTarget.setCustomValidity("Please enter a value");
                } else {
                  typedTarget.setCustomValidity("");
                }
              }}
              required
            />

            <Text size="2" weight="medium">
              Properties
            </Text>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="properties">
                {(provided) => (
                  <Grid
                    gap="2"
                    columns="1fr"
                    align="center"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {formState.properties.map((property, i) => (
                      <Draggable
                        key={`property-${"_id" in property ? property._id : i}`}
                        draggableId={`property-${"_id" in property ? property._id : i}`}
                        index={i}
                      >
                        {(provided) => (
                          <PropertyRow
                            property={property}
                            onEdit={(key, value) =>
                              handleEditProperty(i, key, value)
                            }
                            onRemove={() => handleRemoveProperty(i)}
                            testId={`${BASE_TESTID}-property-${i}`}
                            properties={formState.properties}
                            dragHandleProps={provided.dragHandleProps}
                            draggableProps={provided.draggableProps}
                            ref={provided.innerRef}
                            type={type}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              variant="outline"
              type="button"
              color="gray"
              onClick={handleAddProperty}
              className="max-w-max"
            >
              <Plus /> Add Property
            </Button>
          </Grid>

          {/* Dialog and form actions */}
          <Flex
            gap="2"
            justify="end"
            data-testid={`${BASE_TESTID}-dialog-actions`}
          >
            <Dialog.Close disabled={isSubmitting}>
              <Button
                size="2"
                variant="soft"
                type="button"
                color="gray"
                data-testid={`${BASE_TESTID}-dialog-cancel`}
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              size="2"
              type="submit"
              disabled={
                !formState.properties.find((p) => p.name.length) || isSubmitting
              }
              loading={isSubmitting}
              data-testid={`${BASE_TESTID}-dialog-submit`}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
}

// This uses CreateKnowledgeDataModelRequest here since we cannot reliably say
// that _id is guaranteed to exist, especially when adding new fields to an
// existing model.
type PropertyProps = CreateKnowledgeDataModelRequest["properties"][number];
type PropertyRowProps = {
  property: PropertyProps;
  onEdit<K extends keyof PropertyProps>(key: K, value: PropertyProps[K]): void;
  onRemove(): void;
  testId: string;
  properties: CreateKnowledgeDataModelRequest["properties"];
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  draggableProps?: DraggableProvidedDraggableProps;
  type: "create" | "edit";
};

const PropertyRow = forwardRef<HTMLDivElement, PropertyRowProps>(
  function PropertyRow(
    {
      property,
      onEdit,
      onRemove,
      testId,
      properties,
      dragHandleProps,
      draggableProps,
      type,
    },
    ref,
  ) {
    return (
      <Grid
        ref={ref}
        {...draggableProps}
        columns="auto 1fr 1fr auto auto"
        align="center"
        gap="2"
      >
        <div {...dragHandleProps} className="flex cursor-move items-center">
          <DotsSixVertical />
        </div>
        <TextField.Root
          type="text"
          value={property.name}
          name="property-name"
          onChange={(e) => onEdit("name", e.target.value)}
          onInput={({ target }) => {
            const typedTarget = target as HTMLInputElement;

            // find if another field already has the same value
            const duplicateField = properties.find(
              (p) => p.name === typedTarget.value && p !== property,
            );

            if (!typedTarget.value.trim().length) {
              typedTarget.setCustomValidity("Please enter a value");
            } else if (duplicateField) {
              typedTarget.setCustomValidity("Property name must be unique.");
            } else {
              typedTarget.setCustomValidity("");
            }
          }}
          placeholder="Property Name"
          data-testid={`${testId}-name`}
          className="flex-1"
          required
        />

        <Select.Root
          value={property.type}
          onValueChange={(value) =>
            onEdit("type", value as KnowledgeDataModelPropertyType)
          }
          data-testid={`${testId}-type`}
          disabled={!isNil((property as KnowledgeDataModelProperty)._id)}
        >
          <Select.Trigger placeholder="Property Type" />
          <Select.Content>
            <Select.Item value="text">Text</Select.Item>
            <Select.Item value="number">Number</Select.Item>
            <Select.Item value="date">Date</Select.Item>
            <Select.Item value="checkbox">Checkbox</Select.Item>
            <Select.Item value="url">URL</Select.Item>
            {/* <Select.Item value="file">File Upload</Select.Item> */}
          </Select.Content>
        </Select.Root>
        <label
          htmlFor={`${testId}-required`}
          className="flex items-center gap-2"
        >
          <Checkbox
            id={`${testId}-required`}
            checked={property.required}
            onCheckedChange={(checked) =>
              onEdit("required", checked as boolean)
            }
            data-testid={`${testId}-required`}
            disabled={
              (type !== "create" && !property.required) ||
              property.type === "checkbox"
            }
          />
          <Text>Required</Text>
        </label>
        <IconButton
          size="1"
          variant="ghost"
          color="gray"
          onClick={onRemove}
          type="button"
          data-testid={`${testId}-remove`}
          disabled={property.isSystem}
        >
          <Trash />
        </IconButton>
      </Grid>
    );
  },
);

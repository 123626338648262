import { Warning } from "@phosphor-icons/react";
import { Flex, Grid, Text } from "@radix-ui/themes";
import cx from "classnames";
import { useEffect } from "react";
import { Tldraw, TLStoreWithStatus } from "tldraw";

import { Toolbar } from "./Toolbar";

import {
  assetUrls,
  injectCustomCursorStyles,
} from "../../../../../../utils/tldraw/assets";
import { externalAssetHandler } from "../../../../../../utils/tldraw/handlers";
import { customShapeUtils } from "../../../../../../utils/tldraw/shapeUtils";
import { customTools } from "../../../../../../utils/tldraw/tools";
import { Checkerboard } from "../../../../components/Checkerboard";
import { useTicket } from "../../view";

const MAX_ASSET_SIZE = 104_857_600; // About 100MB
const ACCEPTED_FILE_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
  "image/svg+xml",
  "application/illustrator",
  "application/eps",
  "application/postscript",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/pdf",
  "application/zip",
  "application/*",
  "image/*",
] as const;

type PunttTldrawProps = {
  store: TLStoreWithStatus;
  mode: "doc" | "canvas";
};

export default function PunttTldraw(props: PunttTldrawProps) {
  const { mode, store } = props;
  const { setEditor } = useTicket();

  useEffect(() => {
    // this is needed to inject the custom cursor styles
    // safari isn't interpolating css colors with %23
    injectCustomCursorStyles();
  }, []);

  return (
    <Grid className="relative">
      <Flex
        wrap="nowrap"
        align="center"
        gap="1"
        p="2"
        className={cx(
          "absolute right-1 top-1 z-10 size-7 overflow-hidden whitespace-pre-wrap rounded-md bg-puntt-neutral-gray-5 p-1 shadow-2 hover:h-auto hover:w-[calc(100%-0.5rem)] [&:hover_.rt-Text]:opacity-100",
          {
            hidden: ["loading", "synced-remote"].includes(store.status),
          },
        )}
      >
        <Flex style={{ flexShrink: 0 }}>
          <Warning color="var(--orange-10)" size={20} cursor="help" />
        </Flex>
        <Text className="opacity-0">
          Real-time syncing disconnected. Your changes will still save
          automatically, but you may not see other people&apos;s cursors.
        </Text>
      </Flex>

      <Tldraw
        initialState="combo"
        acceptedImageMimeTypes={ACCEPTED_FILE_MIME_TYPES}
        assetUrls={assetUrls}
        components={{
          Grid: (gridProps) => <Checkerboard mode={mode} {...gridProps} />,
          Spinner: null,
          ContextMenu: null,
        }}
        hideUi
        maxAssetSize={MAX_ASSET_SIZE}
        onMount={(editor) => {
          setEditor(editor);
          // @ts-expect-error TS2339: `import.meta` is strictly typed
          if (import.meta.NODE_ENV !== "production") {
            // @ts-expect-error TS2339: `window` is strictly typed
            window.UnsafeDeps = Object.assign({}, window.UnsafeDeps, {
              tldraw: { editor },
            });
          }

          // Handles when the user drops a file onto the canvas from outside the
          // browser window.
          editor.registerExternalAssetHandler(
            "file",
            externalAssetHandler(editor) as Parameters<
              typeof editor.registerExternalAssetHandler
            >[0], // This can return null or undefined, which doesn't cause errors, but the tldraw type doesn't allow that
          );

          // Enable the checkerboard grid
          editor.updateInstanceState({ isGridMode: true });
        }}
        shapeUtils={customShapeUtils}
        store={store}
        tools={customTools}
      >
        <Toolbar />
      </Tldraw>
    </Grid>
  );
}

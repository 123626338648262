import { Combobox } from "@headlessui/react";
import {
  CaretDown,
  GridFour,
  ListBullets,
  MagnifyingGlass,
  UserCircle,
} from "@phosphor-icons/react";
import {
  Badge,
  Button,
  DropdownMenu,
  Flex,
  Heading,
  Link,
  SegmentedControl,
  Spinner,
  Text,
  TextField,
} from "@radix-ui/themes";
import {
  // eslint-disable-next-line import/named
  Link as NavLink,
  useNavigate,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import cx from "classnames";
import { usePostHog } from "posthog-js/react";
import { useEffect, useMemo, useRef, useState } from "react";

import { Breadcrumbs } from "./Breadcrumbs";

import { useUI } from "../../../contexts/ui";
import { errorAnalyticsPayload } from "../../../utils/analytics";
import { isPunttGuest } from "../../../utils/auth";
import { batch } from "../../../utils/batch";
import { debounce } from "../../../utils/debounce";
import { useAppSelector } from "../../../utils/hooks";
import { errorMessage } from "../../../utils/http";
import {
  useEditProjectMutation,
  useUpdateFolderMutation,
} from "../../../utils/queries/projects";
import { ticketsRoute } from "../route";

type TicketsHeaderProps = {
  isPending: boolean;
  onSearch: (hasUserSearched: boolean) => void;
  onFilesUpload(): void;
  onFolderUpload(): void;
  onNewFolder(): void;

  // Breadcrumb actions
  onDeleteFolder(folderId: string): void;
  onRenameFolder(folderId: string): void;
  onShareFolder(folderId: string): void;
  onDownloadFolder(folderId: string): void;
};

export function TicketsHeader(props: TicketsHeaderProps) {
  const {
    isPending,
    onSearch,
    onFilesUpload,
    onNewFolder,
    onFolderUpload,
    onDeleteFolder,
    onShareFolder,
    onRenameFolder,
    onDownloadFolder,
  } = props;

  const posthog = usePostHog();

  const availableParticipants = useAppSelector(
    (state) => state.punttProjects.participants,
  );
  const { folderId, view, participants, ticketTitle } = useSearch({
    from: ticketsRoute.id,
  });
  const navigate = useNavigate({ from: ticketsRoute.to });
  const router = useRouter();

  const { notify } = useUI();

  const breadcrumbs = useAppSelector(
    (state) => state.punttProjects.breadcrumbs,
  );
  const updateFolderMutation = useUpdateFolderMutation();
  const editProjectMutation = useEditProjectMutation();
  useEffect(() => {
    document.title = `Puntt | ${breadcrumbs.at(-1)?.name ?? "Projects"}`;
    return () => {
      document.title = "Puntt";
    };
  }, [breadcrumbs]);

  const [searchState, setSearchState] = useState({
    ticketTitle: ticketTitle ?? "",
    participants: participants ?? [],
  });

  const onMoveToFolder = async (
    draggedIds: { _id: string; isFolder: boolean }[],
    targetFolderId: string,
  ) => {
    const startTime = performance.now();

    try {
      await batch(
        draggedIds,
        1,
        async ([dragTargetId]) => {
          if (dragTargetId.isFolder) {
            await updateFolderMutation.mutateAsync({
              _id: dragTargetId._id,
              parentFolder: targetFolderId,
            });
          } else {
            await editProjectMutation.mutateAsync({
              _id: dragTargetId._id,
              folder: targetFolderId,
            });
          }
        },
        5,
      );
      router.invalidate();
      posthog.capture("tickets_dragged_to_sidebar", {
        folder_id: targetFolderId,
        dropped_ids: draggedIds,
        duration_ms: performance.now() - startTime,
        num_dropped: draggedIds.length,
      });
    } catch (error) {
      const message = await errorMessage(error);
      notify({
        title: "Error dragging to folder",
        message,
      });
      posthog.capture("tickets_dragged_to_sidebar_error", {
        folder_id: targetFolderId,
        num_dropped: draggedIds.length,
        dropped_ids: draggedIds,
        duration_ms: performance.now() - startTime,
        ...errorAnalyticsPayload(error),
      });
    }

    router.invalidate();
  };

  // useMemo here so that we don't keep redeclaring the timeout on component
  // re-render
  const handleSearch = useMemo(
    () =>
      debounce((newSearchState: typeof searchState) => {
        const hasUserSearched =
          newSearchState.ticketTitle.trim().length > 0 ||
          newSearchState.participants.length > 0;

        onSearch(hasUserSearched);
        navigate({
          search(prev) {
            return {
              ...prev,
              ticketTitle: newSearchState.ticketTitle,
              participants: newSearchState.participants,
            };
          },
          replace: true,
        });
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const queryRef = useRef<HTMLInputElement>(null);
  const [participantsQuery, setParticipantsQuery] = useState("");

  const filteredParticipants =
    participantsQuery === ""
      ? availableParticipants
      : availableParticipants.filter((p) =>
          p.name
            ?.toLocaleLowerCase()
            .includes(participantsQuery.toLocaleLowerCase()),
        );
  return (
    <header className="mx-10 flex items-center justify-between gap-4">
      <Flex align="center" gap="2">
        <Heading as="h1" size="5" weight="bold">
          <Link asChild color="gray" data-auth-trigger="projects-link">
            <NavLink
              to={ticketsRoute.to}
              search={({ folderId: _folderId, ...prev }) => prev}
            >
              Projects
            </NavLink>
          </Link>
        </Heading>

        {folderId && (
          <Breadcrumbs
            onDeleteFolder={() => onDeleteFolder(folderId)}
            onShareFolder={() => onShareFolder(folderId)}
            onRenameFolder={() => onRenameFolder(folderId)}
            onDownloadFolder={() => onDownloadFolder(folderId)}
            onMoveToFolder={(id, folder) => onMoveToFolder(id, folder)}
          />
        )}
      </Flex>

      <section className="flex min-w-0 shrink-0 items-center gap-2">
        <Spinner size="1" className={cx({ hidden: !isPending })} mr="2" />

        <TextField.Root
          placeholder="Search Ticket Name"
          ref={queryRef}
          size="1"
          defaultValue={ticketTitle}
          onInput={({ target }) => {
            const newState = {
              ...searchState,
              ticketTitle: (target as HTMLInputElement).value,
            };
            setSearchState(newState);
            handleSearch(newState);
          }}
          className="shrink-0"
        >
          <TextField.Slot>
            <MagnifyingGlass />
          </TextField.Slot>
        </TextField.Root>

        <Combobox
          multiple
          value={participants ?? []}
          as="div"
          className="relative rounded-md focus-within:ring-2 focus-within:ring-puntt-blue-8 focus-within:ring-offset-[-1px]"
          disabled={isPending}
          onChange={(newParticipants) => {
            const newState = {
              ...searchState,
              participants: newParticipants,
            };
            setSearchState(newState);
            handleSearch(newState);
          }}
        >
          <Combobox.Button className="flex items-center gap-1 overflow-hidden rounded-md border border-puntt-neutral-gray-7 bg-base-white px-0.5 py-[3px]">
            <UserCircle className="text-puntt-neutral-gray-9" />
            {Array.isArray(participants) && participants.length > 0 && (
              <Badge size="1" variant="surface">
                {participants.length} selected{" "}
              </Badge>
            )}
            <Combobox.Input
              onChange={({ target }) => setParticipantsQuery(target.value)}
              className="border-none text-xs outline-transparent"
              placeholder="Search Participants"
            />
            <CaretDown />
          </Combobox.Button>
          <Combobox.Options className="absolute top-full z-[2] mt-0.5 grid max-h-72 w-full gap-1 overflow-auto rounded-md border border-puntt-neutral-gray-7 bg-base-white p-1 shadow-lg">
            {filteredParticipants.map((p) => (
              <Combobox.Option
                key={p._id}
                value={p._id}
                disabled={isPending}
                className={({ active, selected, disabled }) =>
                  cx("cursor-pointer rounded-lg px-2 py-0.5", {
                    "opacity-50": disabled,
                    "bg-puntt-blue-5": active && selected && !disabled,
                    "bg-puntt-blue-9 text-base-white": selected || active,
                  })
                }
              >
                <Text size="1">{p.name}</Text>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox>

        <SegmentedControl.Root
          defaultValue={view}
          size="1"
          onValueChange={(value) => {
            posthog.capture("ticket_view_changed", {
              view: value,
            });

            localStorage.setItem("layout", value as "list" | "grid");

            navigate({
              search(prev) {
                return {
                  ...prev,
                  view: value as typeof view,
                };
              },
              replace: true,
            });
          }}
        >
          <SegmentedControl.Item value="list">
            <ListBullets />
          </SegmentedControl.Item>
          <SegmentedControl.Item value="grid">
            <GridFour />
          </SegmentedControl.Item>
        </SegmentedControl.Root>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger disabled={isPunttGuest()}>
            <Button size="1">
              New <CaretDown />
            </Button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Content>
            <DropdownMenu.Item onClick={onFilesUpload}>
              File Upload
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={onFolderUpload}>
              Folder Upload
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={onNewFolder}>
              New Folder
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </section>
    </header>
  );
}

import { addPasswordRoute } from "./add-password/route";
import { authLayoutRoute } from "./auth-layout/route";
import { contactsRoute } from "./contacts/route";
import { discoverRoute } from "./discover/route";
import { errorRoute } from "./error/route";
import { forgotPasswordRoute } from "./forgot-password/route";
import { indexRoute } from "./index/route";
import { listRoute } from "./list/route";
import { listsRoute } from "./lists/route";
import { loginRoute } from "./login/route";
import { messagesRoute } from "./messages/route";
import { myNetworkRoute } from "./network/route";
import { newMessageRoute } from "./new-message/route";
import { onboardingRoute } from "./onboarding/route";
import { projectImageUploadRoute } from "./onboarding/routes/create-project";
import { defineRoleRoute } from "./onboarding/routes/define-role";
import { importPortfolioRoute } from "./onboarding/routes/import-portfolio";
import { introExplanationRoute } from "./onboarding/routes/intro-explanation";
import { nameAvatarRoute } from "./onboarding/routes/name-avatar";
import { projectDetailsRoute } from "./onboarding/routes/project-details";
import { reviewPortfolioRoute } from "./onboarding/routes/review-portfolio";
import { whatsNextRoute } from "./onboarding/routes/whats-next";
import { workedWithReviewRoute } from "./onboarding/routes/worked-with-review";
import { privacyPolicyRoute } from "./privacy-policy/route";
import { projectRoute } from "./profile/project-route";
import { profileRoute } from "./profile/route";
import { resetPasswordRoute } from "./reset-password/route";
import { settingsRoute } from "./settings/route";
import { knowledgeDatabaseRoute } from "./settings/routes/knowledge-database/route";
import { knowledgeSettingRoute } from "./settings/routes/knowledgeSettingRoute";
import { usersRoute } from "./settings/routes/usersRoute";
import { threadRoute } from "./thread/route";
import { ticketsRoute } from "./tickets/route";
import { figmaPluginRoute } from "./tickets/routes/figma-ticket";
import { ticketRoute } from "./tickets/routes/ticket/route";
import { ticketCompatibilityRoute } from "./tickets/routes/ticket_compat";
import { tutorialTicketsRoute } from "./tutorial/route";
import { tutorialTicketRoute } from "./tutorial/routes/tutorialTicketRoute";
import { unauthLayoutRoute } from "./unauth-layout/route";
import { editProfileRoute } from "./userProfile/base-edit-route";
import { userProfileRoute } from "./userProfile/route";
import { aboutMeEditRoute } from "./userProfile/routes/AboutMe/editRoute";
import { myExpertiseEditRoute } from "./userProfile/routes/MyExpertise/editRoute";
import { myWorkEditRoute } from "./userProfile/routes/MyWork/editRoute";
import { myWorkListRoute } from "./userProfile/routes/MyWork/viewRoute";
import { workedWithRoute } from "./worked-with/route";

export { rootRoute } from "./root/route";
export const appRoutes = [
  indexRoute,
  privacyPolicyRoute,
  authLayoutRoute.addChildren([
    myNetworkRoute,
    workedWithRoute,
    contactsRoute,
    discoverRoute,
    listsRoute.addChildren([listRoute]),
    profileRoute,
    onboardingRoute,
    projectImageUploadRoute,
    projectDetailsRoute,
    workedWithReviewRoute,
    userProfileRoute,
    messagesRoute,
    threadRoute,
    addPasswordRoute,
    myWorkListRoute,
    myWorkEditRoute,
    newMessageRoute,
    reviewPortfolioRoute,
    importPortfolioRoute,
    defineRoleRoute,
    nameAvatarRoute,
    introExplanationRoute,
    whatsNextRoute,
    projectRoute,
    editProfileRoute.addChildren([aboutMeEditRoute, myExpertiseEditRoute]),
    ticketsRoute.addChildren([ticketCompatibilityRoute, ticketRoute]),
    settingsRoute,
    knowledgeSettingRoute,
    knowledgeDatabaseRoute,
    usersRoute,
    figmaPluginRoute,
    tutorialTicketsRoute,
    tutorialTicketRoute,
    errorRoute,
  ]),
  unauthLayoutRoute.addChildren([
    loginRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
  ]),
];

import { isMongoId } from "validator";
import { z } from "zod";

import { withApiAttributes } from "./common";

/////
// Base schemas and types
/////

const knowledgeDataModelPropertyType = [
  "text",
  "number",
  "date",
  "checkbox",
  "url",
  "file",
  "single-select",
  "multi-select",
] as const;
export type KnowledgeDataModelPropertyType =
  (typeof knowledgeDataModelPropertyType)[number];

export const knowledgeDataModelPropertySchema = z.object({
  _id: z.string().refine(isMongoId),
  name: z.string().min(1),
  type: z.enum(knowledgeDataModelPropertyType),
  required: z.boolean(),
  isSystem: z.boolean(), // if it's a system property, then we don't allow deleting
});
export type KnowledgeDataModelProperty = z.infer<
  typeof knowledgeDataModelPropertySchema
>;

export const knowledgeDataModelSchema = z
  .object({
    name: z.string().min(1),
    enterpriseId: z.string().refine(isMongoId),
    isSystem: z.boolean(), // system record type can be edited, but not deleted
    properties: z.array(knowledgeDataModelPropertySchema),
  })
  .merge(withApiAttributes);
export type KnowledgeDataModel = z.infer<typeof knowledgeDataModelSchema>;

export const knowledgeDataRecordPropertySchema =
  knowledgeDataModelPropertySchema
    .omit({
      required: true, // we already have this on knowledgeDataModelSchema, which the FE should have ahead of time
      type: true, // we already have this on knowledgeDataModelSchema, which the FE should have ahead of time
      isSystem: true, // users cannot delete a field from the edit form
    })
    .extend({
      value: z.union([
        z.string(),
        z.number(),
        z.boolean(),
        z.null(),
        z.undefined(),
      ]),
      propertyId: z.string().refine(isMongoId),
    });
export type KnowledgeDataRecordProperty = z.infer<
  typeof knowledgeDataRecordPropertySchema
>;

export const knowledgeDataRecordSchema = z
  .object({
    modelId: z.string().refine(isMongoId),
    enterpriseId: z.string().refine(isMongoId), // frontend doesn't need this, but it's ok
    data: z.array(knowledgeDataRecordPropertySchema),
  })
  .merge(withApiAttributes);
export type KnowledgeDataRecord = z.infer<typeof knowledgeDataRecordSchema>;

/////
// API schemas and types
/////

///// Record Types, Databases, Schemas

// GET /v1/private/catalyst/knowledgeDataModels
// Just use KnowledgeDataModel[]

// POST /v1/private/catalyst/knowledgeDataModels

export const createKnowledgeDataModelRequestSchema = knowledgeDataModelSchema
  .omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
    properties: true,
    enterpriseId: true,
  })
  .extend({
    properties: z.array(
      knowledgeDataModelPropertySchema.omit({
        _id: true,
      }),
    ),
  });
export type CreateKnowledgeDataModelRequest = z.infer<
  typeof createKnowledgeDataModelRequestSchema
>;

// PUT /v1/private/catalyst/knowledgeDataModels/:dbModelId
// Just use KnowledgeDataModel

// DELETE /v1/private/catalyst/knowledgeDataModels/:dbModelId
// Nothing for delete

///// Records

// GET /v1/catalyst/knowledgeDataModels/:dbModelId/knowledgeDataRecords

export const getKnowledgeDataModelRecordsQuerySchema = z.object({
  pageSize: z.coerce.number().optional().default(100),
  searchAfter: z.string().optional(), // e.g. "2025-10-13T11:28:34Z-67855b69eba8ac3032cd7e87"; objectId here is from the last row item
  query: z.string().optional(),
});
export type GetKnowledgeDataModelRecordsQuery = z.infer<
  typeof getKnowledgeDataModelRecordsQuerySchema
>;

export const getKnowledgeDataModelRecordsResponseSchema = z.object({
  // used only on the knowledge database page. citations should be the full object.
  dataRecords: z.array(
    knowledgeDataRecordSchema.omit({
      modelId: true,
      enterpriseId: true,
    }),
  ),
  more: z.boolean(),
});
export type GetKnowledgeDataModelRecordsResponse = z.infer<
  typeof getKnowledgeDataModelRecordsResponseSchema
>;

// GET /v1/catalyst/knowledgeDataRecords/:recordId
// Just returns a KnowledgeDataRecord

// POST /v1/catalyst/knowledgeDataModels/:dbModelId/knowledgeDataRecords
// Just use KnowledgeDataRecordProperty[]; BE will need to validate that it didn't get anything it didn't like, e.g. an unexpected field or field value
// Returns a KnowledgeDataRecord

// PUT /v1/catalyst/knowledgeDataModels/:dbModelId/knowledgeDataRecords/:recordId
// Just use KnowledgeDataRecord

// DELETE /v1/catalyst/knowledgeDataModels/:dbModelId/knowledgeDataRecords/:recordId
// Nothing for delete

import { Button, Dialog, Flex, TextField } from "@radix-ui/themes";
import { useSearch } from "@tanstack/react-router";
import { useState, useEffect, useMemo } from "react";

import { useAppSelector } from "../../../utils/hooks";
import { ticketsRoute } from "../route";

type RenameDialogProps = {
  _id?: string;
  onSave(ticketId: string, name: string): Promise<any> | void;
  onCancel(): void;
  isPending: boolean;
};

export function RenameDialog(props: RenameDialogProps) {
  const { isPending, onCancel, onSave, _id } = props;
  const tickets = useAppSelector((state) => state.punttProjects.tickets);
  const folders = useAppSelector((state) => state.punttProjects.folders);
  const breadcrumbs = useAppSelector(
    (state) => state.punttProjects.breadcrumbs,
  );
  const { folderId } = useSearch({
    from: ticketsRoute.id,
  });
  const markedFolders = useMemo(
    () =>
      folders.map((f) => ({
        ...f,
        isFolder: true,
      })),
    [folders],
  );
  const mergedFoldersAndTickets = useMemo(
    () =>
      [...markedFolders, ...tickets].toSorted((a, b) => {
        const dateA = new Date(a.updatedAt as string);
        const dateB = new Date(b.updatedAt as string);

        // Sort in descending order (most recent first)
        return dateB.getTime() - dateA.getTime();
      }),
    [markedFolders, tickets],
  );

  const found =
    mergedFoldersAndTickets.find((t) => t._id === _id) ??
    breadcrumbs.find((b) => b._id === _id);
  const isFolder = found && "isFolder" in found && found?.isFolder;
  // @ts-expect-error TS2339: Yeah, this type is really messed up.
  const [name, setName] = useState(found?.name ?? found?.title ?? "");
  useEffect(() => {
    // @ts-expect-error TS2339: Yeah, this type is really messed up.
    setName(found?.name ?? found?.title ?? "");
  }, [found?._id]);

  return (
    <Dialog.Root open={_id != null}>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>
          Rename {isFolder || _id === folderId ? "folder" : "ticket"}?
        </Dialog.Title>
        <Dialog.Description className="hidden">
          Rename <em>{name}</em>
        </Dialog.Description>
        <TextField.Root
          placeholder="Folder Name"
          name="folder-name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          disabled={isPending}
          onKeyDown={async (e) => {
            if (e.key === "Enter" && !isPending) {
              e.preventDefault();
              await onSave(_id as string, name);
              setName("");
            }
          }}
        />

        <Flex gap="2" mt="3" justify="end">
          <Button
            variant="soft"
            color="gray"
            onClick={() => {
              onCancel();
              setName("");
            }}
          >
            Cancel
          </Button>
          <Button
            color="blue"
            onClick={async () => {
              await onSave(_id as string, name);
              setName("");
            }}
            disabled={isPending || !name.trim().length}
            loading={isPending}
          >
            Rename
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

import { Code, Grid, Heading, Text } from "@radix-ui/themes";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorComponent } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../../utils/hooks";
import { errorMessage } from "../../../utils/http";

export const TicketsError: typeof ErrorComponent = ({
  error,
}: {
  error: Error | Response;
}) => {
  const queryErrorResetBoundary = useQueryErrorResetBoundary();
  const { drawerOpen } = useAppSelector((state) => state.ui);
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (error instanceof Response && error.status === 401) {
      setMessage("You are not authorized to access this folder.");
    } else {
      errorMessage(error).then(setMessage);
    }
  }, [error]);

  useEffect(() => {
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  return (
    <Grid
      gap="4"
      className={cx("mx-auto mt-40 max-w-prose text-center", {
        "ml-[calc(50%+10rem)] -translate-x-1/2": drawerOpen,
      })}
    >
      <Heading color="red">Error</Heading>

      <Text color="red">
        Unable to retrieve tickets because an error occurred:
      </Text>

      <Code color="red">{message}</Code>

      <Text color="red">
        Please try again shortly or contact support if the problem persists.
      </Text>
    </Grid>
  );
};

import {
  Button,
  Dialog,
  Em,
  Flex,
  Grid,
  VisuallyHidden,
} from "@radix-ui/themes";

import { isNil } from "../../../../../utils/fp";
import { KnowledgeDataModel } from "../../../../../utils/parsers/knowledge-db";
import { BaseDialogProps } from "../../../../../utils/types/dialog";

type DeleteRecordTypeDialogProps = BaseDialogProps & {
  model?: KnowledgeDataModel;
  onSubmit(model: KnowledgeDataModel): void;
};

const BASE_TESTID = "delete-record-type-dialog";

export default function DeleteRecordTypeDialog(
  props: DeleteRecordTypeDialogProps,
) {
  const { isSubmitting, open, onOpenChange, onSubmit, model } = props;

  if (isNil(model)) return null;

  return (
    <Dialog.Root
      open={open}
      onOpenChange={onOpenChange}
      data-testid={BASE_TESTID}
    >
      <Dialog.Content
        size="2"
        width="450px"
        data-testid={`${BASE_TESTID}-content`}
      >
        <Dialog.Title>
          Are you sure you want to delete record type <Em>{model.name}</Em>?
        </Dialog.Title>

        <Grid gap="3">
          <VisuallyHidden>
            <Dialog.Description>
              This will make all of its records permanently inaccessible.
              Existing AI comments can still show those records as a Source.
            </Dialog.Description>
          </VisuallyHidden>

          <Flex
            gap="2"
            justify="end"
            data-testid={`${BASE_TESTID}-dialog-actions`}
          >
            <Dialog.Close disabled={isSubmitting}>
              <Button
                size="2"
                variant="soft"
                color="gray"
                data-testid={`${BASE_TESTID}-dialog-cancel`}
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              size="2"
              color="red"
              onClick={() => onSubmit(model)}
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid={`${BASE_TESTID}-dialog-submit`}
            >
              Delete
            </Button>
          </Flex>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
}

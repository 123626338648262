import { ButtonNew, Icon } from "@mg/dali/src";
import { type WorkosLoginResponse } from "@mg/schemas/src/prince/auth";
import { Text } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import { Navigate } from "@tanstack/react-router";
import { useState, useRef, useCallback } from "react";

import { LoginForm } from "./LoginForm";
import { MfaForm } from "./MfaForm";
import { loginRoute } from "./route";

import { LoginLayout } from "../../components/layouts/login";
import { workOsLogin, verifyMfa } from "../../services/auth";
import { errorAnalyticsPayload, useAnalytics } from "../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { login } from "../../utils/slices/auth";
import { ticketsRoute } from "../tickets/route";

function useWorkosLogin() {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: workOsLogin,
  });
}

function useMfaVerification() {
  return useMutation({
    mutationKey: ["verifyMfa"],
    mutationFn: verifyMfa,
  });
}

export function LoginView() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.value);
  const searchParams = loginRoute.useSearch();
  const [isFormValid, setIsFormValid] = useState(false);
  const [mfaData, setMfaData] = useState<WorkosLoginResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const loginMutation = useWorkosLogin();
  const mfaMutation = useMfaVerification();

  const posthog = useAnalytics("LoginView");

  const formRef = useRef<HTMLFormElement>(null);

  const validateForm = useCallback(() => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const password = formData.get("password") as string;
      const email = formData.get("email") as string;
      setIsFormValid(!!email && !!password);
    }
  }, []);

  const handleLoginSuccess = (data: WorkosLoginResponse, startTime: number) => {
    if (data.token) {
      dispatch(login({ token: data.token }));
      posthog.capture("login_success", {
        requestDurationSeconds: (performance.now() - startTime) / 1000,
        referredBy: user?.referredBy,
      });
    } else {
      posthog.capture("login_mfa_step", {
        requestDurationSeconds: (performance.now() - startTime) / 1000,
        referredBy: user?.referredBy,
      });
      setMfaData(data);
    }
  };

  const handleLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const startTime = performance.now();

    loginMutation.mutate(
      {
        email: formData.get("email") as string,
        password: formData.get("password") as string,
      },
      {
        onSuccess: (data) => handleLoginSuccess(data!, startTime),
        onError: (error) => {
          setErrorMessage(error.message);
          posthog.capture("login_failure", {
            requestDurationSeconds: (performance.now() - startTime) / 1000,
            referredBy: user?.referredBy,
            ...errorAnalyticsPayload(error),
          });
        },
      },
    );
  };

  const handleMfaVerification = (otp: string) => {
    const startTime = performance.now();
    if (mfaData) {
      mfaMutation.mutateAsync(
        {
          authenticationChallengeId: mfaData.authenticationChallenge
            ?.id as string,
          pendingAuthenticationToken:
            mfaData.pendingAuthenticationToken as string,
          code: otp,
        },
        {
          onSuccess: (data) => {
            dispatch(login({ token: data!.token as string }));
            posthog.capture("mfa_success", {
              requestDurationSeconds: (performance.now() - startTime) / 1000,
              referredBy: user?.referredBy,
            });
          },
          onError: (error) => {
            setErrorMessage(error.message);
            posthog.capture("mfa_failure", {
              requestDurationSeconds: (performance.now() - startTime) / 1000,
              referredBy: user?.referredBy,
            });
          },
        },
      );
    }
  };

  if (user) {
    let searchRedirect: string;

    if (searchParams.redirect != null) {
      const { redirect } = searchParams;
      if (redirect.startsWith("/")) {
        searchRedirect = redirect;
      } else {
        searchRedirect = `/${redirect}`;
      }
    } else {
      searchRedirect = ticketsRoute.to;
    }

    return <Navigate to={searchRedirect} />;
  }

  return (
    <LoginLayout>
      {!mfaData ? (
        <>
          {searchParams.error ? (
            <Text color="red" size="2" className="mb-4 block">
              {searchParams.error === "session-expired"
                ? "Your session has expired. Please log in again."
                : null}
            </Text>
          ) : null}
          <LoginForm
            formRef={formRef}
            isValid={isFormValid}
            onSubmit={handleLoginSubmit}
            loading={loginMutation.isPending}
            disabled={loginMutation.isPending}
            error={loginMutation.isError}
            checkValidity={validateForm}
          />
        </>
      ) : (
        <div>
          <ButtonNew
            variant="ghost"
            onClick={() => {
              setMfaData(null);
            }}
          >
            <Icon.ArrowLeft />
            Back
          </ButtonNew>
          <MfaForm
            mfaOptions={mfaData}
            loading={mfaMutation.isPending}
            error={mfaMutation.isError}
            onVerify={handleMfaVerification}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </LoginLayout>
  );
}

import { z } from "zod";

import { baseTicketCommentSchema } from "../christo/catalyst";
import { briefFileSchema, ticketSchema, workflowSchema } from "../commons";

export enum AIType {
  ASK_PUNTT = "ap",
  CREATIVE_SUMMARY = "cs",
  TICKET_COMMENTS = "tc",
  TICKET_BRIEF = "tb",
  TICKET_PROMPT = "tp",
  TICKET_VALIDATE_BRIEF = "tvb",
}

export const aiRequestParamsSchema = z.object({
  // creative summary
  creativeId: z.string().optional(),
  queries: z.string().optional(),

  // ticket overall
  ticketId: z.string().optional(),

  // common
  type: z.nativeEnum(AIType).default(AIType.CREATIVE_SUMMARY),
});

export type AIRequestParams = z.infer<typeof aiRequestParamsSchema>;

export const generateBriefBodySchema = z.object({
  ticketId: z.string(),
  prompt: z.string().optional(),
  workflow: workflowSchema.optional(),
});

export type GenerateBriefBody = z.infer<typeof generateBriefBodySchema>;

export const generateBriefResponseSchema = z.object({
  brief: z.string(),
  briefFiles: z.array(briefFileSchema),
  debugSimilar: z.any().optional(),
  debugTimes: z.any().optional(),
});

export type GenerateBriefResponse = z.infer<typeof generateBriefResponseSchema>;

export const generatePromptRequestSchema = z.object({
  title: z.string(),
});

export type GeneratePromptRequest = z.infer<typeof generatePromptRequestSchema>;

export const generatePromptResponseSchema = z.object({
  prompt: z.string(),
  workflow: z.string(),
});

export type GeneratePromptResponse = z.infer<
  typeof generatePromptResponseSchema
>;

export const validateBriefBodySchema = ticketSchema;

export type ValidateBriefBody = z.infer<typeof validateBriefBodySchema>;

export const validateBriefParamsSchema = z.object({
  ticketId: z.string(),
});

export type ValidateBriefParams = z.infer<typeof validateBriefParamsSchema>;

export const validateBriefRequest = z.object({
  body: validateBriefBodySchema,
  params: validateBriefParamsSchema,
});

export type ValidateBriefRequest = z.infer<typeof validateBriefRequest>;

export const validateBriefResponseSchema = z.object({
  valid: z.boolean(),
  alertMessage: z.string().optional(),
  alertLevel: z.enum(["error", "warning"]).optional(),
});

export type ValidateBriefResponse = z.infer<typeof validateBriefResponseSchema>;

export const generateCommentsRequestSchema = z.object({
  ticket_id: z.string(),
  board_id: z.string(),
  personas: z.array(z.string()).optional(),
});

export type GenerateCommentsRequest = z.infer<
  typeof generateCommentsRequestSchema
>;

export const generateCommentsResponseSchema = z.object({
  totalComments: z.coerce.number(),
  allComments: z.array(baseTicketCommentSchema),
  reviewId: z.string().optional(),
});

export type GenerateCommentsResponse = z.infer<
  typeof generateCommentsResponseSchema
>;

export const askPunttRequestSchema = z.object({
  ticketId: z.string(),
  boardId: z.string(),
  commentId: z.string().optional(),
  x: z.coerce.number().optional(),
  y: z.coerce.number().optional(),
  description: z.string(),
  shapeIds: z.array(z.string()).default([]),
  messageId: z.string().optional(),
  isEdit: z.boolean().optional(),
  isRequired: z.boolean().default(false),
  videoStart: z.coerce.number().optional(),
  videoEnd: z.coerce.number().optional(),
  mentions: z.array(z.string()).optional(),
});

export const askPunttResponseSchema = baseTicketCommentSchema;

export type AskPunttRequest = z.infer<typeof askPunttRequestSchema>;
export type AskPunttResponse = z.infer<typeof askPunttResponseSchema>;

import {
  EnterpriseProfileType,
  UserPermission,
} from "@mg/schemas/src/commons/user";
import {
  CaretDown,
  CheckCircle,
  MagnifyingGlass,
  PaperPlaneRight,
  Plus,
} from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  Flex,
  Heading,
  IconButton,
  Popover,
  Select,
  Table,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";
// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";
import cx from "classnames";
import { useMemo, useRef, useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useUI } from "../../../contexts/ui";
import { errorAnalyticsPayload, useAnalytics } from "../../../utils/analytics";
import {
  requiresAuth,
  canManageUsers,
  canAccessPuntt,
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
} from "../../../utils/auth";
import { useAppSelector } from "../../../utils/hooks";
import { errorMessage } from "../../../utils/http";
import {
  useArchiveUser,
  useInviteUser,
  useUserRoleMutation,
} from "../../../utils/queries/users";
import { store } from "../../../utils/store";
import { useGetVisibleUsers } from "../../../utils/tldraw/comments";
import { authLayoutRoute } from "../../auth-layout/route";
import { listsRoute } from "../../lists/route";
import { myNetworkRoute } from "../../network/route";
import { ticketsRoute } from "../../tickets/route";
import { aboutMeEditRoute } from "../../userProfile/routes/AboutMe/editRoute";

export const usersRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "users",
  beforeLoad() {
    const canAccess = canManageUsers();

    if (!canAccess) {
      if (canAccessPuntt()) {
        throw redirect({
          to: ticketsRoute.to,
        } as any);
      }

      if (canAccessCreativeConnect()) {
        // @ts-expect-error TS2345: incorrect typing search params
        throw redirect({
          to: myNetworkRoute.to,
        });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({
          to: aboutMeEditRoute.to,
        });
      }

      if (canAccessLists()) {
        throw redirect({
          to: listsRoute.to,
        });
      }
    }

    requiresAuth();
  },
  component: Users,
});

function Users() {
  const userRoleMutation = useUserRoleMutation();
  const posthog = useAnalytics("Users");
  const { notify } = useUI();
  const { drawerOpen } = useAppSelector((state) => state.ui);

  const [filters, setFilters] = useState<{ name: string }>({ name: "" });

  const { value: currentUser } = store.getState().auth;
  // This is not how we normally like to check for access, but it needs to
  // match the backend, and at the time of writing the backend doesn't support
  // feature flags yet, so we didn't take on that scope as part of this feature.
  const canArchive = currentUser?.permissions?.includes(
    UserPermission.CONNECT_TEAM,
  );

  const [addUserName, setAddUserName] = useState("");
  const [addUserEmail, setAddUserEmail] = useState("");
  const [addUserRole, setAddUserRole] = useState<
    EnterpriseProfileType | undefined
  >(currentUser?.role);
  const [addUserDropdownOpen, setAddUserDropdownOpen] = useState(false);
  const [bulkImportUserCsv, setBulkImportUserCsv] = useState("");
  const [isBulkImporting, setIsBulkImporting] = useState(false);

  const closeNewUserDialogRef = useRef<HTMLButtonElement>(null);
  const closeBulkImportUserDialogRef = useRef<HTMLButtonElement>(null);

  const {
    data: unsortedUsers,
    refetch,
    isPending,
    isRefetching,
  } = useGetVisibleUsers(false);
  const users = useMemo(() => {
    return unsortedUsers
      ?.filter(
        (user) =>
          !filters.name ||
          user.name?.toLowerCase().includes(filters.name.toLowerCase()) ||
          user.email?.toLowerCase().includes(filters.name.toLowerCase()),
      )
      .toSorted((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
  }, [unsortedUsers, filters.name]);

  const archiveUserMutation = useArchiveUser();
  const inviteUserMutation = useInviteUser();

  const isLoading =
    isPending ||
    isRefetching ||
    archiveUserMutation.isPending ||
    inviteUserMutation.isPending;

  const loader = (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-center">
        Loading users&hellip;
      </Table.Cell>
    </Table.Row>
  );

  let allowedRoles: EnterpriseProfileType[] = [];
  const roles = [
    EnterpriseProfileType.CATALYST_REQUESTER,
    EnterpriseProfileType.OPS,
    EnterpriseProfileType.ADMIN,
  ];
  if (currentUser?.role === EnterpriseProfileType.MEANINGFUL_GIGS) {
    roles.push(EnterpriseProfileType.MEANINGFUL_GIGS);
    allowedRoles = roles;
  } else if (currentUser?.role === EnterpriseProfileType.ADMIN) {
    allowedRoles = roles;
  }

  function friendlyRole(role?: EnterpriseProfileType) {
    if (!role) return "";
    if (role === EnterpriseProfileType.OPS) {
      return "Normal";
    }
    if (role === EnterpriseProfileType.CATALYST_REQUESTER) {
      return "Restricted";
    }
    return role
      .replace(/-/g, " ")
      .replace(
        /\w\S*/g,
        (w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase(),
      );
  }

  function saveNewUserHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const startTime = performance.now();

    inviteUserMutation.mutate(
      {
        enterpriseId: currentUser?.enterpriseId as string,
        name: addUserName,
        email: addUserEmail,
        role: addUserRole!,
      },
      {
        onSuccess: (data) => {
          refetch();

          posthog.capture("user_invited", {
            userId: data?._id,
            userEmail: addUserEmail,
            userName: addUserName,
            userRole: addUserRole,
            durationSeconds: (performance.now() - startTime) / 1000,
          });

          closeNewUserDialogRef.current?.click();

          notify({
            variant: "success",
            title: `${addUserName} successfully invited`,
            message: "User has been invited to the platform.",
            leadingIcon: <CheckCircle color="rgb(var(--base-black))" />,
          });

          setAddUserName("");
          setAddUserEmail("");
          setAddUserRole(currentUser?.role);
        },
        onError: (error) => {
          posthog.capture("user_invited_error", {
            userEmail: addUserEmail,
            userName: addUserName,
            userRole: addUserRole,
            durationSeconds: (performance.now() - startTime) / 1000,
            ...errorAnalyticsPayload(error),
          });

          notify({
            variant: "error",
            title: `Failed to invite ${addUserName}`,
            message: error.message,
          });

          throw error;
        },
      },
    );
  }

  function roleSelector() {
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          Role
        </Text>
        <Select.Root
          value={addUserRole}
          onValueChange={(value) => {
            setAddUserRole(value as EnterpriseProfileType);
          }}
        >
          <Select.Trigger className="w-full">
            <Text as="span" size="1" className="capitalize">
              {friendlyRole(addUserRole)}
            </Text>
          </Select.Trigger>
          <Select.Content>
            {(allowedRoles.length ? allowedRoles : [currentUser?.role]).map(
              (role) => (
                <Select.Item
                  key={role}
                  value={role as string}
                  className="capitalize data-[highlighted]:bg-base-black data-[highlighted]:text-base-white"
                >
                  {friendlyRole(role)}
                </Select.Item>
              ),
            )}
          </Select.Content>
        </Select.Root>
      </label>
    );
  }

  function newUserForm() {
    return (
      <form onSubmit={saveNewUserHandler}>
        <Flex direction="column" gap="3">
          <label htmlFor="new-user-name">
            <Text as="div" size="2" mb="1" weight="bold">
              Name
            </Text>
            <TextField.Root
              id="new-user-name"
              placeholder="Enter name"
              value={addUserName}
              onChange={(e) => setAddUserName(e.target.value)}
              required
            />
          </label>
          <label htmlFor="new-user-email">
            <Text as="div" size="2" mb="1" weight="bold">
              Email
            </Text>
            <TextField.Root
              id="new-user-email"
              placeholder="Enter email"
              value={addUserEmail}
              onChange={(e) => setAddUserEmail(e.target.value)}
              required
            />
          </label>
          {roleSelector()}
        </Flex>
        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close
            data-testid="add-user-cancel"
            onClick={(e) => e.stopPropagation()}
            ref={closeNewUserDialogRef}
          >
            <Button variant="soft" color="gray" className="mr-auto">
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            type="submit"
            data-testid="add-user-confirm"
            disabled={inviteUserMutation.isPending}
            loading={inviteUserMutation.isPending}
          >
            <PaperPlaneRight />
            Send Invitation
          </Button>
        </Flex>
      </form>
    );
  }

  async function bulkImportUserHandler(
    event: React.FormEvent<HTMLFormElement>,
  ) {
    event.preventDefault();
    const startTime = performance.now();
    setIsBulkImporting(true);
    setAddUserDropdownOpen(false);

    const csvRows = bulkImportUserCsv.trim().split("\n");
    if (!csvRows.length) {
      notify({
        variant: "error",
        title: "No users to import",
        message: "Please enter a list of users to import.",
      });
      return;
    }

    const domains = new Set(
      users?.map((user) => user.email?.split("@")[1]) ?? [],
    );

    // Error types
    const invalidRows = [];
    const newDomains = new Set<string>();
    const alreadyExists = [];
    const failed = [];

    const succeeded: string[] = [];
    for (const row of csvRows) {
      if (!row.trim()) continue;
      const [name, email] = row.split(",").map((part) => part.trim());
      if (!name && !email) continue;
      if (!name || !email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        invalidRows.push(row);
        continue;
      }
      if (!domains.has(email.split("@")[1])) {
        newDomains.add(email.split("@")[1]);
      }
      if (
        users?.some((user) => user.email === email) ||
        succeeded.includes(email)
      ) {
        alreadyExists.push(`${name} <${email}>`);
        continue;
      }

      try {
        // To avoid sending too many invites at once, we only send one at a time
        await inviteUserMutation.mutateAsync({
          enterpriseId: currentUser?.enterpriseId as string,
          name,
          email,
          role: addUserRole!,
        });
        succeeded.push(email);
      } catch (error) {
        const message = await errorMessage(error);
        failed.push(`${name} <${email}>: ${message}`);
      }
    }

    // It would be nice to show these all at once.
    if (failed.length) {
      notify({
        variant: "error",
        title: `Error inviting ${failed.length.toLocaleString()} user${failed.length === 1 ? "" : "s"}`,
        message: failed.join("\n"),
      });
    }
    if (invalidRows.length) {
      notify({
        variant: "error",
        title: `${invalidRows.length.toLocaleString()} invalid user${invalidRows.length === 1 ? "" : "s"}`,
        message: invalidRows.join("\n"),
      });
    }
    if (newDomains.size) {
      notify({
        variant: "default",
        title: `Users invited from ${newDomains.size.toLocaleString()} new domain${newDomains.size === 1 ? "" : "s"}`,
        message: Array.from(newDomains).join("\n"),
      });
    }
    if (alreadyExists.length) {
      notify({
        variant: "default",
        title: `${alreadyExists.length.toLocaleString()} user${alreadyExists.length === 1 ? "" : "s"} already added`,
        message: alreadyExists.join("\n"),
      });
    }
    // Show the success message last, otherwise people might see a green toast and leave without seeing the other messages.
    if (succeeded.length) {
      notify({
        variant: "success",
        title: `${succeeded.length.toLocaleString()} user${succeeded.length === 1 ? "" : "s"} successfully invited`,
        // Not showing `message: succeeded.join("\n"),` because it could be very long
        leadingIcon: <CheckCircle color="rgb(var(--base-black))" />,
      });
    }

    posthog.capture("bulk_import_users", {
      role: addUserRole,
      rows: csvRows.length,
      numSucceeded: succeeded.length,
      numFailed: failed.length,
      numInvalid: invalidRows.length,
      numNewDomains: newDomains.size,
      numAlreadyExists: alreadyExists.length,
      succeeded,
      failed,
      invalidRows,
      newDomains,
      alreadyExists,
      durationSeconds: (performance.now() - startTime) / 1000,
    });

    // Clean up
    refetch();
    setBulkImportUserCsv("");
    setAddUserRole(currentUser?.role);
    closeBulkImportUserDialogRef.current?.click();
    setIsBulkImporting(false);
  }

  function bulkImportUserForm() {
    return (
      <form onSubmit={bulkImportUserHandler}>
        <Flex direction="column" gap="3">
          {roleSelector()}
          <label htmlFor="bulk-import-csv">
            <Text as="div" size="2" mb="1" weight="bold">
              Email
            </Text>
            <TextArea
              id="bulk-import-csv"
              placeholder={
                "First Last, name@example.com\nFirst Last, name@example.com"
              }
              value={bulkImportUserCsv}
              onChange={(e) => setBulkImportUserCsv(e.target.value)}
              required
            />
          </label>
        </Flex>
        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close
            data-testid="bulk-import-cancel"
            onClick={(e) => e.stopPropagation()}
            ref={closeBulkImportUserDialogRef}
          >
            <Button variant="soft" color="gray" className="mr-auto">
              Cancel
            </Button>
          </Dialog.Close>
          <Button
            type="submit"
            data-testid="bulk-import-confirm"
            disabled={inviteUserMutation.isPending || isBulkImporting}
            loading={inviteUserMutation.isPending || isBulkImporting}
          >
            <PaperPlaneRight />
            Send Invitations
          </Button>
        </Flex>
      </form>
    );
  }

  return (
    <Flex
      direction="column"
      wrap="nowrap"
      className={cx("bg-puntt-neutral-gray-3 px-12 py-6", {
        "ml-80": drawerOpen,
      })}
      style={{
        minHeight: "calc(100vh - 57px)",
      }}
    >
      <Flex direction="row" wrap="nowrap" className="mb-4 gap-4">
        <Heading as="h1">
          Users
          {users?.length ? ` (${users.length.toLocaleString()})` : ""}
        </Heading>
        <Flex align="center" className="ml-auto gap-x-2">
          <TextField.Root
            placeholder="Search Users"
            size="1"
            defaultValue={filters.name}
            onInput={({ target }) =>
              setFilters({ name: (target as HTMLInputElement).value })
            }
          >
            <TextField.Slot>
              <MagnifyingGlass />
            </TextField.Slot>
          </TextField.Root>

          <Popover.Root
            open={addUserDropdownOpen}
            onOpenChange={setAddUserDropdownOpen}
          >
            <Flex>
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button variant="solid" size="1" className="rounded-r-none">
                    <Plus />
                    Add User
                  </Button>
                </Dialog.Trigger>
                <Dialog.Content style={{ maxWidth: 350 }}>
                  <Dialog.Title>New User</Dialog.Title>
                  <Dialog.Description className="hidden">
                    Enter the name and email and choose the role of the user you
                    want to add.
                  </Dialog.Description>
                  {newUserForm()}
                </Dialog.Content>
              </Dialog.Root>

              <Popover.Trigger>
                <IconButton
                  size="1"
                  className="rounded-l-none border-0 border-l border-solid border-l-puntt-neutral-gray-10 transition-[border-radius] data-[state=open]:rounded-br-none"
                >
                  <CaretDown />
                </IconButton>
              </Popover.Trigger>
            </Flex>

            <Popover.Content
              align="end"
              sideOffset={0}
              className="rounded-sm rounded-tr-none p-0"
            >
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button variant="solid" size="1" className="rounded-tr-none">
                    <Plus />
                    Bulk Import Users
                  </Button>
                </Dialog.Trigger>
                <Dialog.Content style={{ maxWidth: 350 }}>
                  <Dialog.Title>Bulk import users</Dialog.Title>
                  <Dialog.Description className="hidden">
                    Put each user on a separate line, with the name and email
                    separated by a comma.
                  </Dialog.Description>
                  {bulkImportUserForm()}
                </Dialog.Content>
              </Dialog.Root>
            </Popover.Content>
          </Popover.Root>
        </Flex>
      </Flex>
      <Text size="1" color="gray" className="max-w-[40rem]">
        Users with the Restricted role can only access files and folders that
        they upload or that are shared with them. They can&apos;t dismiss (but
        can resolve) others&apos; comments. If a user signs up for Puntt through
        a shared file or folder, they get the Restricted role by default. Normal
        and Admin users can access all files and folders and dismiss
        others&apos; comments, including AI comments. Admins can also change AI
        comments to be Required or Suggested. Contact Meaningful Gigs to change
        who has access to manage users, change the guidelines, or run AI
        Reviews.
      </Text>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Role</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isLoading && loader}
          {users?.map((user) => (
            <Table.Row
              key={user._id}
              className="bg-[#F9F9F9] hover:bg-carbon-50"
            >
              <Table.Cell className="align-middle">
                <AvatarWithInitials
                  avatar={user?.avatar}
                  name={user?.name}
                  size={8}
                  square
                />
                <Text weight="bold" className="pl-2">
                  {user.name}
                </Text>
              </Table.Cell>
              <Table.Cell className="align-middle">{user.email}</Table.Cell>
              <Table.Cell className="align-middle capitalize">
                {currentUser?.userID === user._id || !allowedRoles.length ? (
                  friendlyRole(user.role)
                ) : (
                  <Select.Root
                    value={user.role}
                    disabled={userRoleMutation.isPending}
                    onValueChange={(newRole) => {
                      if (user.role !== newRole) {
                        posthog.capture("user_role_updated", {
                          userId: user._id,
                          userEmail: user.email,
                          oldRole: user.role,
                          userRole: newRole,
                        });

                        userRoleMutation.mutate(
                          { userId: user._id, role: newRole },
                          {
                            onSuccess() {
                              refetch();
                              notify({
                                variant: "default",
                                title: "Role updated",
                                message: `Updated ${user.name}'s role from ${friendlyRole(user.role)} to ${friendlyRole(newRole as EnterpriseProfileType)}`,
                              });
                            },
                            onError() {
                              notify({
                                variant: "default",
                                title: "Sorry",
                                message:
                                  "Updating role failed. This error has been reported to Meaningful Gigs.",
                              });
                            },
                          },
                        );
                      }
                    }}
                  >
                    <Select.Trigger className="w-full">
                      <Text as="span" size="1" className="capitalize">
                        {friendlyRole(user.role)}
                      </Text>
                    </Select.Trigger>
                    <Select.Content>
                      {allowedRoles.map((role) => (
                        <Select.Item
                          key={role}
                          value={role}
                          className="capitalize data-[highlighted]:bg-base-black data-[highlighted]:text-base-white"
                        >
                          {friendlyRole(role)}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select.Root>
                )}
              </Table.Cell>
              <Table.Cell className="align-middle">
                {canArchive && (
                  <Dialog.Root>
                    <Dialog.Trigger>
                      <Button
                        variant="outline"
                        className="m-1 hover:bg-puntt-accent-10 hover:text-base-white"
                        size="1"
                      >
                        Archive
                      </Button>
                    </Dialog.Trigger>
                    <Dialog.Content className="grid w-[90vw] max-w-lg gap-4">
                      <Dialog.Title>
                        Archive <em>{user.name}</em>
                      </Dialog.Title>
                      <Dialog.Description>
                        Are you sure you want to permanently archive this user?
                      </Dialog.Description>
                      <div className="mt-4 flex items-center justify-end gap-2">
                        <Dialog.Close
                          data-testid="archive-cancel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </Dialog.Close>
                        <Dialog.Close
                          data-testid="archive-confirm"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button
                            onClick={() => {
                              const startTime = performance.now();
                              archiveUserMutation.mutate(user._id, {
                                onSuccess: () => {
                                  refetch();

                                  posthog.capture("user_archived", {
                                    userId: user._id,
                                    userEmail: user.email,
                                    userName: user.name,
                                    userRole: user.role,
                                    durationSeconds:
                                      (performance.now() - startTime) / 1000,
                                  });

                                  notify({
                                    variant: "success",
                                    title: `${user.name} successfully archived`,
                                    message: "User has been archived.",
                                    leadingIcon: (
                                      <CheckCircle color="rgb(var(--base-black))" />
                                    ),
                                  });
                                },
                                onError: (error) => {
                                  posthog.capture("user_archived_error", {
                                    userId: user._id,
                                    userEmail: user.email,
                                    userName: user.name,
                                    userRole: user.role,
                                    durationSeconds:
                                      (performance.now() - startTime) / 1000,
                                    ...errorAnalyticsPayload(error),
                                  });

                                  notify({
                                    variant: "error",
                                    title: `Failed to archive ${user.name}`,
                                    message: error.message,
                                  });

                                  throw error;
                                },
                              });
                            }}
                          >
                            Archive
                          </Button>
                        </Dialog.Close>
                      </div>
                    </Dialog.Content>
                  </Dialog.Root>
                )}
              </Table.Cell>
            </Table.Row>
          )) ??
            (!isLoading && loader)}
        </Table.Body>
      </Table.Root>
    </Flex>
  );
}

import { Button, Dialog, Flex, Grid, VisuallyHidden } from "@radix-ui/themes";
import { type FormEventHandler } from "react";

import { DynamicInput } from "./DynamicInput";

import { createPropertiesFromForm } from "../../../../../utils/helpers/createPropertiesFromForm";
import {
  type GetKnowledgeDataModelRecordsResponse,
  type KnowledgeDataModelProperty,
  type KnowledgeDataRecordProperty,
} from "../../../../../utils/parsers/knowledge-db";
import { type BaseDialogProps } from "../../../../../utils/types/dialog";

type RecordDialogProps = BaseDialogProps & {
  recordTypeName: string;
  schema: KnowledgeDataModelProperty[];
} & (
    | {
        type: "create";
        onSubmit(properties: Omit<KnowledgeDataRecordProperty, "_id">[]): void;
      }
    | {
        type: "edit";
        initialData?: GetKnowledgeDataModelRecordsResponse["dataRecords"][number];
        onSubmit(
          record: GetKnowledgeDataModelRecordsResponse["dataRecords"][number],
        ): void;
      }
  );

export default function RecordDialog(props: RecordDialogProps) {
  const {
    isSubmitting,
    open,
    onOpenChange,
    onSubmit,
    recordTypeName,
    schema,
    type,
  } = props;

  const BASE_TESTID = `${type}-record-dialog`;

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget as HTMLFormElement);
    const formState = new Map(formData.entries());
    const properties = createPropertiesFromForm(schema, formState);

    // Regardless of type, we need to better think about how file handling will
    // be done. For example, we should upload to S3 first, return the string,
    // and prevent "submitting" until after the upload has succeeded.
    if (type === "create") {
      onSubmit(properties);
    } else if (type === "edit") {
      const { initialData } = props;
      // initialData cannot be undefined if we got here.
      const definedInitialData = initialData!;
      const copy = {
        ...definedInitialData,
        data: properties.map((p) => ({
          ...p,
          _id: definedInitialData.data.find(
            (d) => d.propertyId === p.propertyId,
          )!._id,
        })),
      };

      onSubmit(copy);
    }
  };
  return (
    <Dialog.Root
      open={open}
      onOpenChange={onOpenChange}
      data-testid={BASE_TESTID}
    >
      <Dialog.Content
        size="2"
        width="500px"
        data-testid={`${BASE_TESTID}-content`}
      >
        <Dialog.Title data-testid={`${BASE_TESTID}-title`}>
          <span className="capitalize">{type}</span> Record
        </Dialog.Title>

        <VisuallyHidden>
          <Dialog.Description data-testid={`${BASE_TESTID}-description`}>
            <span className="capitalize">{type}</span> a new record in the{" "}
            {recordTypeName} database
          </Dialog.Description>
        </VisuallyHidden>

        <form
          onSubmit={handleSubmit}
          className="grid gap-3"
          data-testid={`${BASE_TESTID}-form-area`}
        >
          <Grid
            align="center"
            justify="start"
            columns="auto 1fr"
            gap="4"
            data-testid={`${BASE_TESTID}-form`}
          >
            {schema.map((property) => (
              <DynamicInput
                key={property._id}
                baseTestId={BASE_TESTID}
                {...property}
                // Uncontrolled form when creating
                value={
                  type === "edit"
                    ? props.initialData?.data.find(
                        (p) => p.name === property.name,
                      )?.value
                    : undefined
                }
              />
            ))}
          </Grid>

          {/* Dialog and form actions*/}
          <Flex
            gap="2"
            justify="end"
            data-testid={`${BASE_TESTID}-dialog-actions`}
          >
            <Dialog.Close disabled={isSubmitting}>
              <Button
                size="2"
                variant="soft"
                color="gray"
                data-testid={`${BASE_TESTID}-dialog-cancel`}
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              size="2"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid={`${BASE_TESTID}-dialog-submit`}
            >
              Save
            </Button>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
}

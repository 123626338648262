import {
  baseTicketCommentSchema,
  type GetTicketCommentsResponse,
} from "@mg/schemas/src/christo/catalyst";
import { Robot } from "@phosphor-icons/react";
import {
  HTMLContainer,
  Rectangle2d,
  ShapeUtil,
  type RecordProps,
  type TLBaseShape,
  T,
  type TLShapeId,
} from "@tldraw/tldraw";
import cx from "classnames";
import { useEffect, useMemo } from "react";

import { AvatarWithInitials } from "../../../../components/AvatarWithInitials";
import { isNil } from "../../../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { assetForUser } from "../../../../utils/imageHandler";
import { setActiveCommentId } from "../../../../utils/slices/ticket";
import { isCommentShapeVisible } from "../../../../utils/tldraw/comments";

export type TicketComment = GetTicketCommentsResponse[number];

type CommentShape = TLBaseShape<
  "comment",
  {
    w: number;
    h: number;
    commentId: unknown;
  }
>;

export class CommentAvatarShapeUtil extends ShapeUtil<CommentShape> {
  static override type = "comment-avatar" as const;

  static override props: RecordProps<CommentShape> = {
    w: T.number,
    h: T.number,
    commentId: T.unknown,
  };

  override isAspectRatioLocked = (_shape: CommentShape) => false;
  override canResize = (_shape: CommentShape) => false;
  override hideRotateHandle = (_shape: CommentShape) => true;
  override canEdit = () => true;

  getDefaultProps(): CommentShape["props"] {
    return {
      w: 25,
      h: 25,
      commentId: null,
    };
  }

  private isVisible(shape: CommentShape, userId?: string) {
    return isCommentShapeVisible(shape, userId, true);
  }

  getGeometry(shape: CommentShape) {
    const zoomLevel = this.editor.getZoomLevel();

    return new Rectangle2d({
      width: shape.props.w / zoomLevel,
      height: shape.props.h / zoomLevel,
      isFilled: true,
    });
  }

  component = (shape: CommentShape) => {
    const zoomLevel = this.editor.getZoomLevel();

    // Slices
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.value);
    const highlightedCommentId = useAppSelector(
      (state) => state.ticket.highlightedCommentId,
    );

    // Local state
    const comment = useMemo(() => {
      if (isNil(shape.meta) || isNil(shape.meta.comment)) return null;

      try {
        const commentObj = JSON.parse(shape.meta.comment as string);
        const comment = baseTicketCommentSchema.parse(commentObj);

        return comment;
      } catch {
        return null;
      }
    }, [shape.meta?.comment]);

    const isSelected = this.editor.getSelectedShapeIds().includes(shape.id);

    // Effect chain

    useEffect(() => {
      if (isSelected) {
        this.editor.bringToFront([shape.meta.linkedCommentId as TLShapeId]);
        this.editor.setSelectedShapes([
          shape.id,
          shape.meta.linkedCommentId as TLShapeId,
        ]);
        if (!isNil(comment?._id)) {
          dispatch(setActiveCommentId(comment._id));
        }
      } else {
        dispatch(setActiveCommentId(null));
      }
    }, [comment, isSelected]);

    /////
    // No hooks beyond this point
    /////

    if (!this.isVisible(shape, user?.userID)) {
      return (
        <div
          style={{
            width: 0,
            height: 0,
          }}
        />
      );
    }

    function renderAvatar() {
      if (comment?.isAI) {
        return (
          <Robot
            className="rounded-full bg-amber-amber10 p-1 text-base-black"
            size={25}
          />
        );
      }

      return (
        // TODO: move to using @radix-ui/themes Avatar instead.
        <AvatarWithInitials
          // @ts-expect-error TS2339: schemas is providing the wrong type
          avatar={assetForUser(
            comment?.createdBy ? comment?.createdBy.avatar : user?.avatar,
          )}
          // @ts-expect-error TS2339: schemas is providing the wrong type
          name={comment?.createdBy?.name ?? user?.name}
          size={6}
        />
      );
    }

    return (
      <HTMLContainer
        style={{
          transform: `scale(${1 / zoomLevel})`,
          position: "relative",
        }}
      >
        <div className="comment-pin relative max-w-max">
          <div
            className={cx("comment-pin w-[25px] rounded-[20px_20px_20px_0]", {
              "bg-base-white": !comment?.isRequired,
              "bg-puntt-red-9": comment?.isRequired,
              "scale-125": highlightedCommentId === comment?._id,
            })}
            style={{
              boxShadow: `0 0 0 2px ${
                comment?.isRequired
                  ? "rgb(var(--puntt-red-9))"
                  : "var(--color-text-shadow)"
              }, 0 1px 3px 2px rgba(0, 0, 0, 0.12), 0 1px 2px 2px rgba(0, 0, 0, 0.24)`,
            }}
          >
            {renderAvatar()}
          </div>
        </div>
      </HTMLContainer>
    );
  };

  indicator = (_shape: CommentShape) => null;
}

import { Grid } from "@radix-ui/themes";
import cx from "classnames";
import { useEffect, useState } from "react";

import { Expandable } from "./Expandable";
import { Markdown } from "./Markdown";

import { KnowledgeDataRecordProperty } from "../utils/parsers/knowledge-db";
import { isValidUrl } from "../utils/validation";

export type PreviewProps = {
  url?: string;
  pageNumber?: number;
  text?: string;
  textPrefix?: string;
  knowledgeDataRecord?: KnowledgeDataRecordProperty[];
};

export function urlToPreviewType(
  url?: string,
  text?: string,
  knowledgeDataRecord?: KnowledgeDataRecordProperty[],
) {
  if (!url) {
    if (text) return "text";
    if (knowledgeDataRecord) return "knowledge";
    return "unknown";
  }
  if (/\?|#/.test(url)) return "iframe";
  if (url.endsWith(".pdf")) return "application/pdf";
  if (url.endsWith(".md")) return "text/markdown";
  if (url.endsWith(".txt")) return "text/plain";
  if (/\.(?:jpe?g|png|gif|bmp|tiff|webp)$/.test(url))
    return "image/" + url.split(".").pop();
  if (/\.(?:mp4|webm|ogv)$/.test(url)) return "video/" + url.split(".").pop();
  if (/(?<!\?|#)\.(?:docx?|xlsx?|pptx?|odt|ods|odp|csv)$/.test(url))
    return "download";
  return "iframe";
}

export function Preview(props: PreviewProps) {
  const { url, pageNumber, text, textPrefix, knowledgeDataRecord } = props;
  const previewType = urlToPreviewType(url, text, knowledgeDataRecord);

  const [mdText, setMdText] = useState("Loading...");
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string>();

  useEffect(() => {
    const fetchMarkdown = async () => {
      if (previewType !== "text/markdown" || !url) return;
      try {
        const md = await (
          await fetch(
            url +
              "?response-content-disposition=inline&response-content-type=text%2Fmarkdown",
          )
        ).text();
        setMdText(md);
      } catch (error) {
        setMdText(`Error loading file: ${error}`);
      }
    };
    fetchMarkdown();
  }, [url, previewType]);

  useEffect(() => {
    const fetchPdf = async () => {
      if (previewType !== "application/pdf" || !url) return;

      // Revoke the previous blob URL if it somehow changed without cleanup
      if (pdfBlobUrl) URL.revokeObjectURL(pdfBlobUrl);

      try {
        const response = await fetch(
          url +
            "?response-content-disposition=inline&response-content-type=application%2Fpdf",
        );
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdfBlobUrl(blobUrl);
      } catch (e) {
        console.error("Error loading PDF:", e);
      }
    };
    fetchPdf();

    return () => {
      if (pdfBlobUrl) {
        URL.revokeObjectURL(pdfBlobUrl);
      }
    };
  }, [url, previewType]);

  return (
    <Grid
      justify={url ? "center" : "start"}
      className={cx("w-full", {
        "h-[calc(100%_-_48px)]": previewType !== "knowledge",
      })}
    >
      {previewType === "application/pdf" ? (
        <object
          data={
            pdfBlobUrl ? `${pdfBlobUrl}#page=${pageNumber ?? 1}` : undefined
          }
          type="application/pdf"
          width="100%"
          height="100%"
          className="border-puntt-neutral-6 grow border"
        >
          {!pdfBlobUrl ? (
            "Loading PDF..."
          ) : (
            <>
              Your browser does not support PDF embedding.{" "}
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
              >
                Download it instead.
              </a>
            </>
          )}
        </object>
      ) : previewType === "knowledge" ? (
        <>
          {knowledgeDataRecord?.map((field, i) => (
            <div className="mb-2 gap-1" key={i} data-name={field.name}>
              <div className="font-bold">{field.name}:</div>
              <div>
                {typeof field.value === "string" && isValidUrl(field.value) ? (
                  urlToPreviewType(field.value) !== "iframe" ? (
                    <Preview url={field.value} />
                  ) : (
                    <a
                      href={field.value}
                      target="_blank"
                      rel="noreferrer"
                      className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
                    >
                      {field.value}
                    </a>
                  )
                ) : typeof field.value === "string" &&
                  !isNaN(new Date(field.value).getTime()) ? (
                  new Date(field.value).toLocaleString()
                ) : (
                  <Expandable
                    onExpand={async () => {
                      if (typeof field.value !== "string") return;
                      try {
                        return JSON.stringify(JSON.parse(field.value), null, 2);
                      } catch {
                        // Don't change the text if it's not JSON
                      }
                    }}
                  >
                    {field.value?.toLocaleString()}
                  </Expandable>
                )}
              </div>
            </div>
          ))}
        </>
      ) : previewType.startsWith("image/") ? (
        <img src={url} alt="Citation source" />
      ) : previewType.startsWith("video/") ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video src={url} controls preload="metadata">
          Your browser does not support video embedding.{" "}
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
          >
            Download it instead.
          </a>
        </video>
      ) : previewType === "download" ? (
        <a
          href={url}
          download
          className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
        >
          Download {url?.split("/").pop()}
        </a>
      ) : previewType === "text/plain" ? (
        <object
          data={url}
          type="text/plain"
          width="100%"
          height="100%"
          className="border-puntt-neutral-6 grow border"
        >
          Your browser does not support plain text embedding.{" "}
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-puntt-accent-12 hover:text-puntt-accent-11 active:text-puntt-accent-10"
          >
            Download it instead.
          </a>
        </object>
      ) : previewType === "text/markdown" ? (
        <Markdown>{mdText}</Markdown>
      ) : previewType === "iframe" ? (
        <iframe
          src={url}
          title="Citation source"
          width="100%"
          height="100%"
          className="border-puntt-neutral-6 grow border"
        />
      ) : previewType === "text" ? (
        <article>
          {textPrefix && (
            <p className="pb-2 text-puntt-neutral-gray-11">
              <em>{textPrefix}</em>
            </p>
          )}
          <Markdown>{text}</Markdown>
        </article>
      ) : (
        <p>Sorry, we can&apos;t preview this file type.</p>
      )}
    </Grid>
  );
}

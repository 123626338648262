import { z } from "zod";

import {
  assetSchema,
  enterpriseBriefGuidelines,
  enterpriseProfileTypeSchema,
  enterpriseSchema,
  userSchema,
  teamSchema,
  workflowSchema,
  enterpriseAIPersonas,
  knowledgeDataRecordPropertySchema,
  enterpriseAIPersonasUpdate,
} from "../commons";
import { folderSchema, folderTreeSchema } from "../commons/folder";
import {
  knowledgeDataModelProperty,
  knowledgeDataModel,
  createKnowledgeDataModelRequestSchema,
} from "../commons/knowledgeDataModel";
import {
  TicketCommentBoard,
  TicketCommentDisposition,
  TicketMessageRating,
  boardSchema,
  fileCoordSchema,
  revisionBoardSchema,
  ticketCommentSchema,
  ticketMessageCitationSchema,
  ticketMessageSchema,
  ticketMessageVoteSchema,
  ticketParticipantsSchema,
  ticketReviewSchema,
  ticketSchema,
  ticketTemplateSchema,
} from "../commons/ticket";

export const baseTicketMessageSchema = ticketMessageSchema.extend({
  _id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  publishedAt: z.string().datetime().optional(),
});

export type BaseTicketMessage = z.infer<typeof baseTicketMessageSchema>;

export const baseTicketCommentSchema = ticketCommentSchema.extend({
  _id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  publishedAt: z.string().datetime().optional(),
  messages: z.array(baseTicketMessageSchema),
});

export type BaseTicketComment = z.infer<typeof baseTicketCommentSchema>;

const baseTicketReviewSchema = ticketReviewSchema.extend({
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type BaseTicketReview = z.infer<typeof baseTicketReviewSchema>;

const baseRevisionBoardSchema = revisionBoardSchema.extend({
  _id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().optional(),
  reviews: z.array(baseTicketReviewSchema),
});

export type BaseRevisionBoard = z.infer<typeof baseRevisionBoardSchema>;

export const baseBoardSchema = boardSchema.extend({
  _id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type BaseBoard = z.infer<typeof baseBoardSchema>;

export const baseWorkflowSchema = workflowSchema.extend({
  _id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type BaseWorkflow = z.infer<typeof baseWorkflowSchema>;

export const baseTicketSchema = ticketSchema
  .extend({
    _id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    briefBoard: baseBoardSchema,
    revisionBoards: z.array(baseRevisionBoardSchema),
    workflow: baseWorkflowSchema,
    thumbnails: z.array(z.string()).default([]),
    isPending: z.boolean().default(false),
    totalFiles: z.number().default(0),
    documentImportType: z.string().optional(),
    totalRevisions: z.number().default(0),
  })
  .omit({ comments: true });

export type BaseTicket = z.infer<typeof baseTicketSchema>;

export const getTicketsPaginatedBodySchema = z.object({
  // validate searchAfter to be "timestamp,_id"
  searchAfter: z.string().optional(),
  ticketTitle: z.string().optional(),
  participants: z.array(z.string()).optional(),
  workflowSteps: z.array(z.number()).optional(),
  completedOnly: z.boolean().optional(),
  pageSize: z.coerce.number().default(100).optional(),
});
export type GetTicketsPaginatedBody = z.infer<
  typeof getTicketsPaginatedBodySchema
>;

export const getTicketsPaginatedRequest = z.object({
  body: getTicketsPaginatedBodySchema,
});

export type GetTicketsPaginatedRequest = z.infer<
  typeof getTicketsPaginatedRequest
>;

export const getFolderTicketsPaginatedBodySchema =
  getTicketsPaginatedBodySchema.extend({
    folderId: z.string().optional(),
    query: z.string().optional(),
  });

export type GetFolderTicketsPaginatedBody = z.infer<
  typeof getFolderTicketsPaginatedBodySchema
>;

export const getFolderTicketsPaginatedRequest = z.object({
  body: getFolderTicketsPaginatedBodySchema,
});

export type GetFolderTicketsPaginatedRequest = z.infer<
  typeof getFolderTicketsPaginatedRequest
>;

export const responseFolderSchema = folderSchema.extend({
  _id: z.string(),
  thumbnails: z.array(z.string()).default([]),
  totalFiles: z.number().default(0),
  participants: ticketParticipantsSchema,
});

export type ResponseFolder = z.infer<typeof responseFolderSchema>;

export const getFolderParamsSchema = z.object({
  folderId: z.string(),
});

export const getBreadcrumbsResponseSchema = z.array(
  z.object({
    _id: z.string(),
    name: z.string(),
  }),
);

export type GetBreadcrumbsResponse = z.infer<
  typeof getBreadcrumbsResponseSchema
>;

export const getFolderTreeResponseSchema = z.array(folderTreeSchema);

export const getTicketsResponseSchema = z.array(
  baseTicketSchema
    .pick({
      _id: true,
      title: true,
      description: true,
      enterprise: true,
      createdAt: true,
      participants: true,
      creatives: true,
      updatedAt: true,
      updatedIndex: true,
      isPending: true,
      thumbnails: true,
      totalFiles: true,
      documentImportType: true,
      totalRevisions: true,
    })
    .extend({
      // Folder fields: always undefined, but helpful for methods that process both folders and tickets
      isFolder: z.boolean().optional(),
    }),
);

export const getFolderTicketsPaginatedResponseSchema = z.object({
  tickets: getTicketsResponseSchema.default([]),
  folders: z.array(responseFolderSchema).optional(),
  more: z.boolean().optional(),
  thumbnails: z.array(z.string()).optional(),
});

export type GetFolderTicketsPaginatedResponse = z.infer<
  typeof getFolderTicketsPaginatedResponseSchema
>;

export type GetTicketsResponse = z.infer<typeof getTicketsResponseSchema>;

export const getTicketsPaginatedResponseSchema = z.object({
  tickets: getTicketsResponseSchema,
  more: z.boolean(),
});

export type GetTicketsPaginatedResponse = z.infer<
  typeof getTicketsPaginatedResponseSchema
>;

export const getTicketParamsSchema = z.object({
  ticketId: z.string(),
});

export type GetTicketParams = z.infer<typeof getTicketParamsSchema>;

export const getTicketRequest = z.object({
  params: getTicketParamsSchema,
});

export type GetTicketRequest = z.infer<typeof getTicketRequest>;

export const getTicketResponseSchema = baseTicketSchema;

export type GetTicketResponse = z.infer<typeof getTicketResponseSchema>;

export const createTicketBodySchema = ticketSchema
  .pick({
    title: true,
    description: true,
    briefFiles: true,
    folder: true,
  })
  .extend({
    teamId: z.string().optional(),
  });

export type CreateTicketBody = z.infer<typeof createTicketBodySchema>;

export const createTicketRequest = z.object({
  body: createTicketBodySchema,
});

export type CreateTicketRequest = z.infer<typeof createTicketRequest>;

export const createTicketResponseSchema = baseTicketSchema;

export type CreateTicketResponse = z.infer<typeof createTicketResponseSchema>;

export const createFolderBodySchema = z.object({
  folder: z.object({
    name: z.string(),
    folderId: z.string().optional(),
  }),
  tickets: z.array(createTicketBodySchema).optional(),
});

export type CreateFolderBody = z.infer<typeof createFolderBodySchema>;

export const CreateFolderRequest = z.object({
  body: createFolderBodySchema,
});

export type CreateFolderRequest = z.infer<typeof CreateFolderRequest>;

export const createFolderResponseSchema = z.object({
  folder: folderSchema,
  tickets: z.array(z.string()),
});

export type CreateFolderResponse = z.infer<typeof createFolderResponseSchema>;

export const updateFolderBodySchema = z.object({
  name: z.string().optional(),
  parentFolder: z.string().optional(),
});

export type UpdateFolderBody = z.infer<typeof updateFolderBodySchema>;

export const updateFolderParamsSchema = z.object({
  folderId: z.string(),
});

export type UpdateFolderParams = z.infer<typeof updateFolderParamsSchema>;

export const updateFolderResponseSchema = folderSchema;

export type UpdateFolderResponse = z.infer<typeof updateFolderResponseSchema>;

export const updateTicketBodySchema = ticketSchema
  .pick({
    _id: true,
    description: true,
    files: true,
    moodBoard: true,
    briefBoard: true,
    status: true,
  })
  .extend({
    workflowStep: z.coerce.number().optional(),
    title: z.string().optional(),
    folder: z.string().optional(),
  });

export type UpdateTicketBody = z.infer<typeof updateTicketBodySchema>;

export const updateTicketRequest = z.object({
  body: updateTicketBodySchema,
});

export type UpdateTicketRequest = z.infer<typeof updateTicketRequest>;

export const updateTicketResponseSchema = baseTicketSchema;

export type UpdateTicketResponse = z.infer<typeof updateTicketResponseSchema>;

export const archiveTicketParamsSchema = z.object({
  ticketId: z.string(),
});

export type ArchiveTicketParams = z.infer<typeof archiveTicketParamsSchema>;

export const archiveTicketRequest = z.object({
  params: archiveTicketParamsSchema,
});

export type ArchiveTicketRequest = z.infer<typeof archiveTicketRequest>;

export const archiveTicketResponseSchema = baseTicketSchema;

export type ArchiveTicketResponse = z.infer<typeof archiveTicketResponseSchema>;

export const downloadFolderParamsSchema = z.object({
  folderId: z.string(),
});

export type DownloadFolderParams = z.infer<typeof downloadFolderParamsSchema>;

export const downloadFolderRequest = z.object({
  params: downloadFolderParamsSchema,
});

export type DownloadFolderRequest = z.infer<typeof downloadFolderRequest>;

export const downloadFolderResponseSchema = z.object({
  url: z.string(),
});

export type DownloadFolderResponse = z.infer<
  typeof downloadFolderResponseSchema
>;

export const downloadTicketParamsSchema = z.object({
  ticketId: z.string(),
});

export type DownloadTicketParams = z.infer<typeof downloadTicketParamsSchema>;

export const downloadTicketRequest = z.object({
  params: downloadTicketParamsSchema,
});

export type DownloadTicketRequest = z.infer<typeof downloadTicketRequest>;

export const downloadTicketResponseSchema = z.object({
  url: z.string(),
});

export type DownloadTicketResponse = z.infer<
  typeof downloadTicketResponseSchema
>;

// Get Brief Templates

export const getBriefTemplatesResponseSchema = z.array(ticketTemplateSchema);

export type GetBriefTemplatesResponse = z.infer<
  typeof getBriefTemplatesResponseSchema
>;

// generate mood board images
export const generateMoodBoardImagesBodySchema = z.object({
  ticketId: z.string().optional(),
  description: z.string().optional(),
});

export type GenerateMoodBoardImagesBody = z.infer<
  typeof generateMoodBoardImagesBodySchema
>;

export const generateMoodBoardImagesRequest = z.object({
  body: generateMoodBoardImagesBodySchema,
});

export type GenerateMoodBoardImagesRequest = z.infer<
  typeof generateMoodBoardImagesRequest
>;

export const generateMoodBoardImagesResponseSchema = z.object({
  images: z.array(assetSchema),
});

export type GenerateMoodBoardImagesResponse = z.infer<
  typeof generateMoodBoardImagesResponseSchema
>;

// Comments endpoint

export const createTicketCommentBodySchema = z.object({
  commentId: z.string().optional(),
  description: z.string(),
  x: z.coerce.number().optional(),
  y: z.coerce.number().optional(),
  videoStart: z.coerce.number().optional(),
  videoEnd: z.coerce.number().optional(),
  aiRating: z.nativeEnum(TicketMessageRating).optional(),
  isPending: z.boolean().default(false).optional(),
  isRequired: z.boolean().default(false).optional(),
  boardType: z.nativeEnum(TicketCommentBoard).optional(),
  boardId: z.string().optional(),
  shapeId: z.string().optional(),
  isReview: z.boolean().default(false).optional(),
  mentions: z.array(z.string()).optional(),
  citations: z.array(ticketMessageCitationSchema).optional(),
  votes: z.array(ticketMessageVoteSchema).optional(),
  rule: z.string().optional(),
  disposition: z
    .nativeEnum(TicketCommentDisposition)
    .default(TicketCommentDisposition.DEFAULT)
    .optional(),
  liked: z.boolean().optional(),
});
export type CreateTicketCommentBody = z.infer<
  typeof createTicketCommentBodySchema
>;

export const createTicketCommentParamsSchema = z.object({
  ticketId: z.string(),
});

export const createTicketCommentRequest = z.object({
  body: createTicketCommentBodySchema,
  params: createTicketCommentParamsSchema,
});

export type CreateTicketCommentRequest = z.infer<
  typeof createTicketCommentRequest
>;

export const createTicketCommentResponseSchema = baseTicketCommentSchema;

export type CreateTicketCommentResponse = z.infer<
  typeof createTicketCommentResponseSchema
>;

export const getTicketCommentsQuerySchema = z.object({
  boardType: z.nativeEnum(TicketCommentBoard).optional(),
});

export type GetTicketCommentsQuery = z.infer<
  typeof getTicketCommentsQuerySchema
>;

export const getTicketCommentsParamsSchema = z.object({
  ticketId: z.string(),
});

export type GetTicketCommentsParams = z.infer<
  typeof getTicketCommentsParamsSchema
>;

export const getTicketCommentsRequest = z.object({
  params: getTicketCommentsParamsSchema,
  query: getTicketCommentsQuerySchema,
});

export type GetTicketCommentsRequest = z.infer<typeof getTicketCommentsRequest>;

export const getTicketCommentsResponseSchema = z.array(baseTicketCommentSchema);

export type GetTicketCommentsResponse = z.infer<
  typeof getTicketCommentsResponseSchema
>;

export const updateTicketCommentBodySchema = createTicketCommentBodySchema.omit(
  { commentId: true },
);

export type UpdateTicketCommentBody = z.infer<
  typeof updateTicketCommentBodySchema
>;

export const updateTicketCommentParamsSchema = z.object({
  ticketId: z.string(),
  messageId: z.string(),
});

export type UpdateTicketCommentParams = z.infer<
  typeof updateTicketCommentParamsSchema
>;

export const updateTicketCommentRequest = z.object({
  body: updateTicketCommentBodySchema,
  params: updateTicketCommentParamsSchema,
});

export type UpdateTicketCommentRequest = z.infer<
  typeof updateTicketCommentRequest
>;

export const updateTicketCommentResponseSchema = baseTicketCommentSchema;

export type UpdateTicketCommentResponse = z.infer<
  typeof updateTicketCommentResponseSchema
>;

export const voteTicketCommentParamsSchema = z.object({
  ticketId: z.string(),
  messageId: z.string(),
});

export type VoteTicketCommentParams = z.infer<
  typeof voteTicketCommentParamsSchema
>;

export const voteTicketCommentBodySchema = z.object({
  vote: z
    .nativeEnum(TicketMessageRating)
    .default(TicketMessageRating.THUMBS_UP),
});

export type VoteTicketCommentBody = z.infer<typeof voteTicketCommentBodySchema>;

export const voteTicketCommentRequest = z.object({
  params: voteTicketCommentParamsSchema,
  body: voteTicketCommentBodySchema,
});

export type VoteTicketCommentRequest = z.infer<typeof voteTicketCommentRequest>;

export const voteTicketCommentResponseSchema = baseTicketCommentSchema;

export type VoteTicketCommentResponse = z.infer<
  typeof voteTicketCommentResponseSchema
>;

export const deleteTicketCommentParamsSchema = z.object({
  ticketId: z.string(),
  messageId: z.string(),
});

export type DeleteTicketCommentParams = z.infer<
  typeof deleteTicketCommentParamsSchema
>;

export const deleteTicketCommentRequest = z.object({
  params: deleteTicketCommentParamsSchema,
});

export type DeleteTicketCommentRequest = z.infer<
  typeof deleteTicketCommentRequest
>;

// Revision endpoints
export const getRevisionsResponseSchema = z.array(baseRevisionBoardSchema);

export type GetRevisionsResponse = z.infer<typeof getRevisionsResponseSchema>;

export const getRevisionStatusResponseSchema = revisionBoardSchema.pick({
  isPending: true,
  isAIReady: true,
});
export type GetRevisionStatusResponse = z.infer<
  typeof getRevisionStatusResponseSchema
>;

export const updateRevisionBoardBodySchema = revisionBoardSchema.pick({
  _id: true,
  shapes: true,
  screenshotUrl: true,
  version: true,
});

export type UpdateRevisionBoardBody = z.infer<
  typeof updateRevisionBoardBodySchema
>;

export const updateRevisionBoardParams = z.object({
  ticketId: z.string(),
});

export type UpdateRevisionBoardsParams = z.infer<
  typeof updateRevisionBoardParams
>;

export const updateRevisionBoardRequest = z.object({
  body: updateRevisionBoardBodySchema,
  params: updateRevisionBoardParams,
});

export type UpdateRevisionBoardRequest = z.infer<
  typeof updateRevisionBoardRequest
>;

export const updateRevisionBoardResponseSchema = baseRevisionBoardSchema;

export type UpdateRevisionBoardsResponse = z.infer<
  typeof updateRevisionBoardResponseSchema
>;

export const softDeleteRevisionBoardParamsSchema = z.object({
  ticketId: z.string(),
});

export type SoftDeleteRevisionBoardParams = z.infer<
  typeof softDeleteRevisionBoardParamsSchema
>;

export const softDeleteRevisionBoardRequest = z.object({
  params: softDeleteRevisionBoardParamsSchema,
  body: z.object({
    boardId: z.string(),
  }),
});

export type SoftDeleteRevisionBoardRequest = z.infer<
  typeof softDeleteRevisionBoardRequest
>;

export const softDeleteRevisionBoardResponseSchema = z.object({
  boards: z.array(baseRevisionBoardSchema),
});

export type SoftDeleteRevisionBoardResponse = z.infer<
  typeof softDeleteRevisionBoardResponseSchema
>;

export const addBoardToRevisionBoardBodySchema = z.object({
  name: z.string().optional(),
  reviewFiles: z.array(assetSchema),
  workingFiles: z.array(assetSchema).optional(), // @deprecated
  documentImportType: z.string().default(""),
});

export type AddBoardToRevisionBoardBody = z.infer<
  typeof addBoardToRevisionBoardBodySchema
>;

export const addBoardToRevisionBoardParamsSchema = z.object({
  ticketId: z.string(),
});

export type AddBoardToRevisionBoardParams = z.infer<
  typeof addBoardToRevisionBoardParamsSchema
>;

export const addBoardToRevisionBoardRequest = z.object({
  body: addBoardToRevisionBoardBodySchema,
  params: addBoardToRevisionBoardParamsSchema,
});

export type AddBoardToRevisionBoardRequest = z.infer<
  typeof addBoardToRevisionBoardRequest
>;

export const addBoardToRevisionBoardResponseSchema = z.object({
  boards: z.array(baseRevisionBoardSchema),
  reviewFiles: z.array(fileCoordSchema),
});

export type AddBoardToRevisionBoardResponse = z.infer<
  typeof addBoardToRevisionBoardResponseSchema
>;

export const viewRevisionBoardParamsSchema = z.object({
  ticketId: z.string(),
  revisionId: z.string(),
});

export type ViewRevisionBoardParams = z.infer<
  typeof viewRevisionBoardParamsSchema
>;

export const viewRevisionBoardRequest = z.object({
  params: viewRevisionBoardParamsSchema,
});

export type ViewRevisionBoardRequest = z.infer<typeof viewRevisionBoardRequest>;

export const viewRevisionBoardResponseSchema = baseRevisionBoardSchema;

export type ViewRevisionBoardResponse = z.infer<
  typeof viewRevisionBoardResponseSchema
>;

// REVIEWS ENDPOINT
export const approveTicketParamsSchema = z.object({
  ticketId: z.string(),
});

export type ApproveTicketParams = z.infer<typeof approveTicketParamsSchema>;

export const approveTicketRequest = z.object({
  params: approveTicketParamsSchema,
});

export type ApproveTicketRequest = z.infer<typeof approveTicketRequest>;

export const approveTicketResponseSchema = baseTicketSchema;

export type ApproveTicketResponse = z.infer<typeof approveTicketResponseSchema>;

export const reviewTicketBodySchema = z.object({
  ticketId: z.string(),
  boardId: z.string(),
  description: z.string().optional(),
  approval: z.boolean(),
  creatives: z.array(z.string()).optional(),
  mentions: z.array(z.string()).optional(),
});

export type ReviewTicketBody = z.infer<typeof reviewTicketBodySchema>;

export const reviewTicketRequest = z.object({
  body: reviewTicketBodySchema,
});

export type ReviewTicketRequest = z.infer<typeof reviewTicketRequest>;

export const reviewTicketResponseSchema = baseTicketSchema;

export type ReviewTicketResponse = z.infer<typeof reviewTicketResponseSchema>;

export const addReviewerToRevisionBoardBodySchema = z.object({
  reviewers: z.array(z.string()),
  note: z.string().optional(),
});

export type AddReviewerToRevisionBoardBody = z.infer<
  typeof addReviewerToRevisionBoardBodySchema
>;

export const addReviewerToRevisionBoardParamsSchema = z.object({
  ticketId: z.string(),
});

export type AddReviewerToRevisionBoardParams = z.infer<
  typeof addReviewerToRevisionBoardParamsSchema
>;

export const addReviewerToRevisionBoardRequest = z.object({
  body: addReviewerToRevisionBoardBodySchema,
  params: addReviewerToRevisionBoardParamsSchema,
});

export type AddReviewerToRevisionBoardRequest = z.infer<
  typeof addReviewerToRevisionBoardRequest
>;

export const addReviewerToRevisionBoardResponseSchema = baseTicketSchema;

export type AddReviewerToRevisionBoardResponse = z.infer<
  typeof addReviewerToRevisionBoardResponseSchema
>;

export const removeReviewerFromRevisionBoardParamsSchema = z.object({
  ticketId: z.string(),
  reviewerId: z.string(),
});

export type RemoveReviewerFromRevisionBoardParams = z.infer<
  typeof removeReviewerFromRevisionBoardParamsSchema
>;

export const removeReviewerFromRevisionBoardRequest = z.object({
  params: removeReviewerFromRevisionBoardParamsSchema,
});

export type RemoveReviewerFromRevisionBoardRequest = z.infer<
  typeof removeReviewerFromRevisionBoardRequest
>;

export const removeReviewerFromRevisionBoardResponseSchema = baseTicketSchema;

export type RemoveReviewerFromRevisionBoardResponse = z.infer<
  typeof removeReviewerFromRevisionBoardResponseSchema
>;

// Workflow endpoints

export const getWorkflowsResponseSchema = z.array(baseWorkflowSchema);

export type GetWorkflowsResponse = z.infer<typeof getWorkflowsResponseSchema>;

// Enterprise endpoints

export const updateEnterpriseSettingsBodySchema = z
  .object({
    logo: z.string().optional(),
    extraFonts: z.array(z.string()).optional(),
  })
  .refine((data) => Object.keys(data).length > 0, {
    message: "At least one field must be provided",
  });

export type UpdateEnterpriseSettingsBody = z.infer<
  typeof updateEnterpriseSettingsBodySchema
>;

export const getBriefGuidelinesResponseSchema = enterpriseBriefGuidelines;

export type GetBriefGuidelinesResponse = z.infer<
  typeof getBriefGuidelinesResponseSchema
>;

export const getKnowledgeSettingsResponseSchema = z.object({
  brandGuidelines: getBriefGuidelinesResponseSchema,
  complianceGuidelines: getBriefGuidelinesResponseSchema,
  aiPersonas: z.array(enterpriseAIPersonas),
});

export const updateKnowledgeSettingsRequest = z.object({
  body: enterpriseBriefGuidelines.extend({
    settingKey: z.enum(["brandGuidelines", "complianceGuidelines"]),
  }),
});

export type UpdateKnowledgeSettingsRequest = z.infer<
  typeof updateKnowledgeSettingsRequest
>;

export type GetKnowledgeSettingsResponse = z.infer<
  typeof getKnowledgeSettingsResponseSchema
>;

export const updateBriefGuidelinesBodySchema = enterpriseBriefGuidelines;

export type UpdateBriefGuidelinesBody = z.infer<
  typeof updateBriefGuidelinesBodySchema
>;

export const updateBriefGuidelinesRequest = z.object({
  body: updateBriefGuidelinesBodySchema,
});

export type UpdateBriefGuidelinesRequest = z.infer<
  typeof updateBriefGuidelinesRequest
>;

export const updateBriefGuidelinesResponseSchema =
  getBriefGuidelinesResponseSchema;

export type UpdateBriefGuidelinesResponse = z.infer<
  typeof updateBriefGuidelinesResponseSchema
>;

export const getAIPersonasResponseSchema = z.array(enterpriseAIPersonas);

export type GetAIPersonasResponse = z.infer<typeof getAIPersonasResponseSchema>;

export const updateAIPersonasBodySchema = enterpriseAIPersonasUpdate;

export type UpdateAIPersonasBody = z.infer<typeof updateAIPersonasBodySchema>;

export const updateAIPersonasRequest = z.object({
  body: updateAIPersonasBodySchema,
});

export type UpdateAIPersonasRequest = z.infer<typeof updateAIPersonasRequest>;

export const updateAIPersonasResponseSchema = getAIPersonasResponseSchema;

export type UpdateAIPersonasResponse = z.infer<
  typeof updateAIPersonasResponseSchema
>;
// Zip file endpoint

export const extractZipFileParamsSchema = z.object({
  ticketId: z.string(),
});

export type ExtractZipFileParams = z.infer<typeof extractZipFileParamsSchema>;

export const extractZipFileBodySchema = z.object({
  filesS3Keys: z.array(z.string()),
});

export type ExtractZipFileBody = z.infer<typeof extractZipFileBodySchema>;

export const extractZipFileRequest = z.object({
  params: extractZipFileParamsSchema,
  body: extractZipFileBodySchema,
});

export type ExtractZipFileRequest = z.infer<typeof extractZipFileRequest>;

export const extractZipFileResponseSchema = z.object({
  previousFiles: z.array(fileCoordSchema),
  files: z.array(fileCoordSchema),
});

export type ExtractZipFileResponse = z.infer<
  typeof extractZipFileResponseSchema
>;

// Users endpoints

export const getUsersQuerySchema = z.object({
  roles: z.array(enterpriseProfileTypeSchema).optional(),
});

export type GetUsersQuery = z.infer<typeof getUsersQuerySchema>;

export const getUsersRequest = z.object({
  query: getUsersQuerySchema,
});

export type GetUsersRequest = z.infer<typeof getUsersRequest>;

export const getUsersResponseSchema = z.array(
  z.object({
    _id: z.string(),
    name: z.string().optional(),
    avatar: z.string().optional(),
    role: enterpriseProfileTypeSchema,
    email: z.string().optional(),
  }),
);

export type GetUsersResponse = z.infer<typeof getUsersResponseSchema>;

export const updateUserRoleResponseSchema = z.object({
  _id: z.string(),
  name: z.string().optional(),
  avatar: z.string().optional(),
  role: enterpriseProfileTypeSchema,
  email: z.string().optional(),
});

export type UpdateUserRoleResponse = z.infer<
  typeof updateUserRoleResponseSchema
>;

export const getDownloadDeliverySchemaParams = z.object({
  ticketId: z.string(),
  boardId: z.string(),
});

export type GetDownloadDeliveryParams = z.infer<
  typeof getDownloadDeliverySchemaParams
>;

export const getDownloadDeliveryRequest = z.object({
  params: getDownloadDeliverySchemaParams,
});

export type GetDownloadDeliveryRequest = z.infer<
  typeof getDownloadDeliveryRequest
>;

export const getDownloadDeliveryResponseSchema = z.object({
  url: z.string(),
});

export type GetDownloadDeliveryResponse = z.infer<
  typeof getDownloadDeliveryResponseSchema
>;

export const sendToDesignBodySchema = z.object({
  creatives: z.array(z.string()),
  note: z.string().optional(),
});

export type SendToDesignBody = z.infer<typeof sendToDesignBodySchema>;

export const sendToDesignRequest = z.object({
  body: sendToDesignBodySchema,
});

export type SendToDesignRequest = z.infer<typeof sendToDesignRequest>;

export const sendToDesignResponseSchema = z.object({
  creatives: z.array(z.string()),
  status: z.string().optional(),
});

export type SendToDesignResponse = z.infer<typeof sendToDesignResponseSchema>;

export const suggestCreativesResponseSchema = z.object({
  suggested: getUsersResponseSchema,
  more: getUsersResponseSchema,
  network: getUsersResponseSchema,
});

export type SuggestCreativesResponse = z.infer<
  typeof suggestCreativesResponseSchema
>;

export const suggestReviewersResponseSchema = getUsersResponseSchema;

export type SuggestReviewersResponse = z.infer<
  typeof suggestReviewersResponseSchema
>;

// Internal routes

export const createEnterpriseBodySchema = z.object({
  name: z.string(),
});

export type CreateEnterpriseBody = z.infer<typeof createEnterpriseBodySchema>;

export const createEnterpriseRequest = z.object({
  body: createEnterpriseBodySchema,
});

export type CreateEnterpriseRequest = z.infer<typeof createEnterpriseRequest>;

export const createEnterpriseResponseSchema = enterpriseSchema
  .omit({
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
  });

export type CreateEnterpriseResponse = z.infer<
  typeof createEnterpriseResponseSchema
>;

export const inviteUserBodySchema = z.object({
  name: z.string(),
  email: z.string(),
  role: enterpriseProfileTypeSchema,
});

export type InviteUserBody = z.infer<typeof inviteUserBodySchema>;

export const inviteUserParamsSchema = z.object({
  enterpriseId: z.string(),
});

export type InviteUserParams = z.infer<typeof inviteUserParamsSchema>;

export const inviteUserRequest = z.object({
  body: inviteUserBodySchema,
  params: inviteUserParamsSchema,
});

export type InviteUserRequest = z.infer<typeof inviteUserRequest>;

export const inviteUserResponseSchema = userSchema;

export type InviteUserResponse = z.infer<typeof inviteUserResponseSchema>;

// Share Ticket endpoint

export const shareTicketParamsSchema = z.object({
  ticketId: z.string(),
});

export type ShareTicketParams = z.infer<typeof shareTicketParamsSchema>;

export const shareTicketBodySchema = z.object({
  emails: z.array(z.string()).optional(),
  version: z.union([z.literal("new"), z.literal("all"), z.number()]).optional(),
});

export type ShareTicketBody = z.infer<typeof shareTicketBodySchema>;

export const shareTicketRequest = z.object({
  params: shareTicketParamsSchema,
  body: shareTicketBodySchema,
});

export type ShareTicketRequest = z.infer<typeof shareTicketRequest>;

export const shareTicketResponseSchema = z.object({
  url: z.string(),
});

export type ShareTicketResponse = z.infer<typeof shareTicketResponseSchema>;

// Share Folder endpoint

export const shareFolderParamsSchema = z.object({
  folderId: z.string(),
});

export type ShareFolderParams = z.infer<typeof shareFolderParamsSchema>;

export const shareFolderBodySchema = z.object({
  emails: z.array(z.string()).optional(),
});

export type ShareFolderBody = z.infer<typeof shareFolderBodySchema>;

export const shareFolderRequest = z.object({
  params: shareFolderParamsSchema,
  body: shareFolderBodySchema,
});

export type ShareFolderRequest = z.infer<typeof shareFolderRequest>;

export const shareFolderResponseSchema = z.object({
  url: z.string(),
});

export type ShareFolderResponse = z.infer<typeof shareFolderResponseSchema>;

// Teams endpoint

export const getTeamsResponseSchema = z.array(teamSchema);

export type GetTeamsResponse = z.infer<typeof getTeamsResponseSchema>;

export const createTeamBodySchema = teamSchema.pick({
  users: true,
  profileSettings: true,
});

export type CreateTeamBody = z.infer<typeof createTeamBodySchema>;

export const createTeamRequest = z.object({
  body: createTeamBodySchema,
});

export type CreateTeamRequest = z.infer<typeof createTeamRequest>;

export const createTeamResponseSchema = teamSchema;

export type CreateTeamResponse = z.infer<typeof createTeamResponseSchema>;

export const addUserToTeamParamsSchema = z.object({
  teamId: z.string(),
});

export type AddUserToTeamParams = z.infer<typeof addUserToTeamParamsSchema>;

export const addUserToTeamBodySchema = z.object({
  userId: z.string().optional(),
});

export type AddUserToTeamBody = z.infer<typeof addUserToTeamBodySchema>;

export const addUserToTeamRequest = z.object({
  params: addUserToTeamParamsSchema,
  body: addUserToTeamBodySchema,
});

export type AddUserToTeamRequest = z.infer<typeof addUserToTeamRequest>;

export const addUserToTeamResponseSchema = teamSchema;

export type AddUserToTeamResponse = z.infer<typeof addUserToTeamResponseSchema>;

export const removeUserFromTeamResponseSchema = teamSchema;

export type RemoveUserFromTeamResponse = z.infer<
  typeof removeUserFromTeamResponseSchema
>;

export const addTicketToTeamParamsSchema = z.object({
  teamId: z.string(),
});

export type AddTicketToTeamParams = z.infer<typeof addTicketToTeamParamsSchema>;

export const addTicketToTeamBodySchema = z.object({
  ticketId: z.string().optional(),
});

export type AddTicketToTeamBody = z.infer<typeof addTicketToTeamBodySchema>;

export const addTicketToTeamRequest = z.object({
  params: addTicketToTeamParamsSchema,
  body: addTicketToTeamBodySchema,
});

export type AddTicketToTeamRequest = z.infer<typeof addTicketToTeamRequest>;

export const addTicketToTeamResponseSchema = baseTicketSchema;

export type AddTicketToTeamResponse = z.infer<
  typeof addTicketToTeamResponseSchema
>;

export const removeTicketFromTeamResponseSchema = baseTicketSchema;

export type RemoveTicketFromTeamResponse = z.infer<
  typeof removeTicketFromTeamResponseSchema
>;

export const getParticipantsResponseSchema = ticketParticipantsSchema;

export type GetParticipantsResponse = z.infer<
  typeof getParticipantsResponseSchema
>;

export const getEnterprisesResponseSchema = z.array(
  createEnterpriseResponseSchema,
);

export const combineTicketsBodySchema = z.object({
  receivingTicketId: z.string(),
  addingTicketId: z.string(),
});

export type CombineTicketsBody = z.infer<typeof combineTicketsBodySchema>;

export const combineTicketsRequest = z.object({
  body: combineTicketsBodySchema,
});

export type CombineTicketsRequest = z.infer<typeof combineTicketsRequest>;

export const createKnowledgeDataModelBodySchema =
  createKnowledgeDataModelRequestSchema;

export const createKnowledgeDataModelRequest = z.object({
  body: createKnowledgeDataModelBodySchema,
});

export type CreateKnowledgeDataModelRequest = z.infer<
  typeof createKnowledgeDataModelRequest
>;

export const createKnowledgeDataModelResponseSchema = z.object({
  _id: z.string(),
  name: z.string(),
  properties: z.array(knowledgeDataModelProperty),
});

export type CreateKnowledgeDataModelResponse = z.infer<
  typeof createKnowledgeDataModelResponseSchema
>;

export const createKnowledgeDataRecordBodySchema = z.array(
  z.object({}).passthrough(),
);

export const optionalKnowledgeDataModelProperty = knowledgeDataModelProperty
  .partial()
  .omit({
    isSystem: true,
  });

export const updateKnowledgeDataModelBodySchema = z.object({
  name: z.string().optional(),
  properties: z.array(optionalKnowledgeDataModelProperty).optional(),
});

export type UpdateKnowledgeDataModelBody = z.infer<
  typeof updateKnowledgeDataModelBodySchema
>;

export const updateKnowledgeDataModelParamsSchema = z.object({
  knowledgeDataModelId: z.string(),
});

export type UpdateKnowledgeDataModelParams = z.infer<
  typeof updateKnowledgeDataModelParamsSchema
>;

export const updateKnowledgeDataModelRequest = z.object({
  body: updateKnowledgeDataModelBodySchema,
  params: updateKnowledgeDataModelParamsSchema,
});

export type UpdateKnowledgeDataModelRequest = z.infer<
  typeof updateKnowledgeDataModelRequest
>;

export const updateKnowledgeDataModelResponseSchema = knowledgeDataModel;

export type UpdateKnowledgeDataModelResponse = z.infer<
  typeof updateKnowledgeDataModelResponseSchema
>;

export const createKnowledgeDataRecordRequest = z.object({
  body: createKnowledgeDataRecordBodySchema,
});

export type CreateKnowledgeDataRecordRequest = z.infer<
  typeof createKnowledgeDataRecordRequest
>;

export const createKnowledgeDataRecordResponseSchema = z
  .object({
    _id: z.string(),
  })
  .catchall(z.any());

export type CreateKnowledgeDataRecordResponse = z.infer<
  typeof createKnowledgeDataRecordResponseSchema
>;

export const getKnowledgeDataRecordParamsSchema = z.object({
  knowledgeRecordId: z.string(),
});

export type GetKnowledgeDataRecordParams = z.infer<
  typeof getKnowledgeDataRecordParamsSchema
>;

export const getKnowledgeDataRecordRequest = z.object({
  params: getKnowledgeDataRecordParamsSchema,
});

export type GetKnowledgeDataRecordRequest = z.infer<
  typeof getKnowledgeDataRecordRequest
>;

export const getKnowledgeDataRecordResponseSchema = z
  .object({
    _id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    deletedAt: z.string().datetime().optional(),
    data: z.array(knowledgeDataRecordPropertySchema),
    modelId: z.string(),
  })
  .passthrough();

export type GetKnowledgeDataRecordResponse = z.infer<
  typeof getKnowledgeDataRecordResponseSchema
>;

export const updateKnowledgeDataRecordBodySchema = z.object({}).passthrough();

export type UpdateKnowledgeDataRecordBody = z.infer<
  typeof updateKnowledgeDataRecordBodySchema
>;

export const updateKnowledgeDataRecordParamsSchema = z.object({
  knowledgeRecordId: z.string(),
});

export type UpdateKnowledgeDataRecordParams = z.infer<
  typeof updateKnowledgeDataRecordParamsSchema
>;

export const updateKnowledgeDataRecordResponseSchema =
  getKnowledgeDataRecordResponseSchema;

export type UpdateKnowledgeDataRecordResponse = z.infer<
  typeof updateKnowledgeDataRecordResponseSchema
>;

export const deleteKnowledgeDataRecordParamsSchema = z.object({
  knowledgeRecordId: z.string(),
});

export type DeleteKnowledgeDataRecordParams = z.infer<
  typeof deleteKnowledgeDataRecordParamsSchema
>;

export const deleteKnowledgeDataRecordRequest = z.object({
  params: deleteKnowledgeDataRecordParamsSchema,
});

export type DeleteKnowledgeDataRecordRequest = z.infer<
  typeof deleteKnowledgeDataRecordRequest
>;

export const deleteKnowledgeDataRecordResponseSchema = z.object({
  _id: z.string(),
});

export type DeleteKnowledgeDataRecordResponse = z.infer<
  typeof deleteKnowledgeDataRecordResponseSchema
>;

export const getAllKnowledgeDataRecordsBodySchema = z.object({
  pageSize: z.coerce.number().default(100).optional(),
  searchAfter: z.string().optional(),
});

export type GetAllKnowledgeDataRecordsBody = z.infer<
  typeof getAllKnowledgeDataRecordsBodySchema
>;

export const getAllKnowledgeDataRecordsQueryParams = z.object({
  pageSize: z.coerce.number().default(100).optional(),
  searchAfter: z.string().optional(),
  query: z.string().optional(),
});

export type GetAllKnowledgeDataRecordsQueryParams = z.infer<
  typeof getAllKnowledgeDataRecordsQueryParams
>;

export const getAllKnowledgeDataRecordsRequest = z.object({
  body: getAllKnowledgeDataRecordsBodySchema,
  params: z.object({ knowledgeDataModelId: z.string() }),
});

export type GetAllKnowledgeDataRecordsRequest = z.infer<
  typeof getAllKnowledgeDataRecordsRequest
>;

export const knowledgeDataRecordResult = z
  .object({
    _id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    deletedAt: z.string().datetime().optional(),
    createdBy: z.string(),
  })
  .passthrough();

export const getAllKnowledgeDataRecordsResponseSchema = z.object({
  dataRecords: z.array(knowledgeDataRecordResult),
  more: z.boolean(),
});

export type GetAllKnowledgeDataRecordsResponse = z.infer<
  typeof getAllKnowledgeDataRecordsResponseSchema
>;

export const getKnowledgeDataModelResponseSchema = z.array(knowledgeDataModel);

export type GetKnowledgeDataModelResponse = z.infer<
  typeof getKnowledgeDataModelResponseSchema
>;

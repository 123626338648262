/**
 * Given a schema and a form state, creates a Knowledge
 * Database Record property array.
 */

import { isNil } from "../fp";
import { type KnowledgeDataModelProperty } from "../parsers/knowledge-db";

export function createPropertiesFromForm(
  schema: KnowledgeDataModelProperty[],
  formState: Map<string, FormDataEntryValue>,
) {
  return schema.map(({ _id, name, type }) => {
    const value = formState.get(`field-${_id}`);

    let parsedValue = value as unknown;

    switch (type) {
      case "number":
        if (!isNil(parsedValue) && (parsedValue as string) !== "") {
          parsedValue = Number(parsedValue);
        } else {
          parsedValue = null;
        }
        break;
      case "date":
      case "text":
      case "url":
      case "file":
        parsedValue = (parsedValue as string) === "" ? null : parsedValue;
        break;
      case "checkbox":
        parsedValue = parsedValue === "on";
        break;
      case "single-select":
      case "multi-select":
        parsedValue = (parsedValue as string) === "" ? null : parsedValue;
        break;
    }

    return {
      name,
      value: parsedValue as string | number | boolean,
      propertyId: _id,
    };
  });
}

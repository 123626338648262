import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import {
  type KnowledgeDataModelProperty,
  type GetKnowledgeDataModelRecordsResponse,
  type KnowledgeDataModel,
  type KnowledgeDataRecord,
} from "../parsers/knowledge-db";

type KnowledgeDatabaseState = {
  models: KnowledgeDataModel[];
  schema: KnowledgeDataModelProperty[] | null;
  records: GetKnowledgeDataModelRecordsResponse["dataRecords"][number][];
  isLoadingMore: boolean;
  hasMore: boolean;
};

const initialState: KnowledgeDatabaseState = {
  models: [],
  schema: null,
  records: [],
  isLoadingMore: false,
  hasMore: false,
};

export const knowledgeDatabaseSlice = createSlice({
  name: "knowledge_database",
  initialState,
  reducers: {
    setModels(state, action: PayloadAction<KnowledgeDatabaseState["models"]>) {
      state.models = action.payload;
    },
    addModel(
      state,
      action: PayloadAction<KnowledgeDatabaseState["models"][number]>,
    ) {
      state.models.push(action.payload);
    },
    updateModel(
      state,
      action: PayloadAction<KnowledgeDatabaseState["models"][number]>,
    ) {
      state.models = state.models.map((model) =>
        model._id === action.payload._id ? action.payload : model,
      );
    },
    removeModel(
      state,
      action: PayloadAction<KnowledgeDatabaseState["models"][number]>,
    ) {
      state.models = state.models.filter(
        (model) => model._id !== action.payload._id,
      );
    },
    setSchema(state, action: PayloadAction<KnowledgeDatabaseState["schema"]>) {
      state.schema = action.payload;
    },
    addRecord(
      state,
      action: PayloadAction<KnowledgeDatabaseState["records"][number]>,
    ) {
      state.records.push(action.payload);
    },
    removeRecord(
      state,
      action: PayloadAction<KnowledgeDatabaseState["records"][number]>,
    ) {
      state.records = state.records.filter(
        (record) => record._id !== action.payload._id,
      );
    },
    setRecords(
      state,
      action: PayloadAction<GetKnowledgeDataModelRecordsResponse>,
    ) {
      state.records = action.payload.dataRecords;
      state.hasMore = action.payload.more;
    },
    updateRecord(state, action: PayloadAction<KnowledgeDataRecord>) {
      state.records = state.records.map((record) =>
        record._id === action.payload._id ? action.payload : record,
      );
    },
    setIsLoadingMore(state, action: PayloadAction<boolean>) {
      state.isLoadingMore = action.payload;
    },
    mergeKnowledgeDatabaseState(
      state,
      action: PayloadAction<Partial<KnowledgeDatabaseState>>,
    ) {
      Object.assign(state, action.payload);
    },
  },
});

export const {
  setModels,
  addModel,
  updateModel,
  removeModel,
  setSchema,
  addRecord,
  removeRecord,
  setRecords,
  updateRecord,
  setIsLoadingMore,
  mergeKnowledgeDatabaseState,
} = knowledgeDatabaseSlice.actions;

import { DotsThreeOutlineVertical, FunnelSimple } from "@phosphor-icons/react";
import {
  Checkbox,
  DropdownMenu,
  IconButton,
  Table,
  Text,
  Tooltip,
  Flex,
} from "@radix-ui/themes";
import cx from "classnames";
import { type FC } from "react";

import { Expandable } from "../../../../../components/Expandable";
import { canMutateKnowledgeDatabaseRecords } from "../../../../../utils/auth";
import { isNil } from "../../../../../utils/fp";
import {
  type KnowledgeDataModelProperty,
  type GetKnowledgeDataModelRecordsResponse,
} from "../../../../../utils/parsers/knowledge-db";

interface TableProps {
  data: GetKnowledgeDataModelRecordsResponse["dataRecords"]; // Array of objects representing rows
  schema: KnowledgeDataModelProperty[]; // Columns to display
  onEdit(recordId: string): void;
  onDelete(recordId: string): void;
  onFilterClick(fieldName: string): void;
}

const { Root, Header, Row, ColumnHeaderCell, Body, Cell } = Table;

export const KnowledgeDatabaseTable: FC<TableProps> = ({
  data,
  schema,
  onEdit,
  onDelete,
  onFilterClick,
}) => {
  const canMutateRecord = canMutateKnowledgeDatabaseRecords();

  return (
    <Root variant="surface">
      <Header>
        <Row>
          {schema.map((col) => {
            let minWidth = "5rem";
            if (col.type === "text") {
              for (const row of data) {
                const rowValue = String(
                  row.data.find((item) => item.propertyId === col._id)?.value,
                );
                if (rowValue.length > 90) {
                  console.log(col.name, rowValue);
                  minWidth = "24rem";
                  break;
                } else if (rowValue.length > 30) {
                  minWidth = "16rem";
                  break;
                }
              }
            }
            return (
              <ColumnHeaderCell
                key={`col-${col._id}`}
                className={cx("break-words", {
                  "max-w-[50vw]": col.type === "text",
                  "max-w-[30vw]": col.type === "url",
                })}
                style={{ minWidth }}
              >
                <Flex align="center" gap="1" className="group">
                  {col.name}
                  <Tooltip content={`Filter by ${col.name}`}>
                    <IconButton
                      size="1"
                      variant="ghost"
                      onClick={() => onFilterClick(col.name)}
                      className="opacity-0 transition-opacity group-hover:opacity-100"
                    >
                      <FunnelSimple />
                    </IconButton>
                  </Tooltip>
                </Flex>
              </ColumnHeaderCell>
            );
          })}
          <ColumnHeaderCell
            className={cx("w-24", {
              hidden: !canMutateRecord,
            })}
          >
            Actions
          </ColumnHeaderCell>
        </Row>
      </Header>
      <Body>
        {data.map((row) => (
          <Row key={`row-${row._id}`}>
            {schema.map((col) => {
              const rowValue = row.data.find(
                (item) => item.propertyId === col._id,
              )?.value;

              return (
                <Cell
                  key={`${col._id}-${row._id}`}
                  className={cx("break-words", {
                    "max-w-[50vw]": col.type === "text",
                    "max-w-[30vw]": col.type === "url",
                  })}
                >
                  {!isNil(rowValue) ? (
                    typeof rowValue === "boolean" ? (
                      <Checkbox checked={rowValue} disabled />
                    ) : col.type === "url" ? (
                      <a
                        href={rowValue as string}
                        target="_blank"
                        rel="noreferrer"
                        className="text-puntt-accent-12 transition-colors hover:text-puntt-accent-10 active:text-puntt-accent-11"
                      >
                        {rowValue}
                      </a>
                    ) : (
                      <Expandable
                        size="2"
                        maxLines={col.isSystem ? 8 : 4}
                        onExpand={async (content) => {
                          if (typeof content !== "string") return;
                          try {
                            return JSON.stringify(JSON.parse(content), null, 2);
                          } catch {
                            // Don't change the text if it's not JSON
                          }
                        }}
                      >
                        {col.name.toLowerCase().endsWith(" id")
                          ? rowValue
                          : rowValue.toLocaleString()}
                      </Expandable>
                    )
                  ) : (
                    <Text color="gray" className="opacity-50">
                      -
                    </Text>
                  )}
                </Cell>
              );
            })}
            <Cell className={cx("w-24", { hidden: !canMutateRecord })}>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <IconButton size="1" variant="outline" color="gray">
                    <DotsThreeOutlineVertical />
                  </IconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content>
                  <DropdownMenu.Item onClick={() => onEdit(row._id)}>
                    Edit
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => onDelete(row._id)}>
                    Delete
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </Cell>
          </Row>
        ))}
      </Body>
    </Root>
  );
};

import { Grid, Text } from "@radix-ui/themes";

export function KnowledgeDatabaseHeader() {
  return (
    <Grid gap="2">
      <Text size="7" weight="bold">
        Knowledge Database
      </Text>
      <Text size="2">
        Manage the information used by Puntt AI to run AI Reviews.
      </Text>
    </Grid>
  );
}

import {
  GetTicketsResponse,
  ResponseFolder,
} from "@mg/schemas/src/christo/catalyst";

import { isNil } from "./fp/isNil";
import { store } from "./store";

type TicketStub = GetTicketsResponse[number];

export function fileToTicketStub(file: File): TicketStub {
  const enterpriseId = store.getState().auth.value?.enterpriseId;
  const nowIso = new Date().toISOString();

  if (isNil(enterpriseId)) {
    throw new Error("Enterprise ID is required to create a ticket stub");
  }

  return {
    _id: crypto.randomUUID(),
    title: file.name,
    isPending: true,
    createdAt: nowIso,
    updatedAt: nowIso,
    enterprise: enterpriseId,
    thumbnails: [],
    participants: [],
    totalRevisions: 1,
    // TODO: this is a naive setting
    totalFiles: 1,
  };
}

type FolderStub = Omit<ResponseFolder, "createdAt" | "updatedAt"> & {
  createdAt: string;
  updatedAt: string;
};

export function directoryNameToFolderStub(
  directoryName: string,
  parentId?: string,
): FolderStub {
  const enterpriseId = store.getState().auth.value?.enterpriseId;
  const now = new Date().toISOString();

  if (isNil(enterpriseId)) {
    throw new Error("Enterprise ID is required to create a folder stub");
  }

  return {
    _id: crypto.randomUUID(),
    name: directoryName,
    createdAt: now,
    updatedAt: now,
    enterprise: enterpriseId,
    parentFolder: parentId,
    thumbnails: [],
    participants: [],
    // TODO: this is a naive setting
    totalFiles: 1,
  };
}

// Matches the sanitizeFilename function from @mg/commons-node/src/libs/utils.ts
// It'd be nice to share them.
export function sanitizeFilename(filename: string): string {
  // Remove diacritics
  filename = filename.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
  // Replace sequences of invalid characters with a hyphen
  filename = filename.replace(/[^a-zA-Z0-9_\-. ]+/g, "-");

  // Limit filename length to 255 characters while preserving extension
  const parts = filename.split(".");
  const ext = parts.length > 1 ? "." + parts.pop() : "";
  let namePart = parts.join(".") || "download";
  // Windows reserved names
  if (/^(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$/.test(namePart)) {
    namePart = "download";
  }
  filename = namePart.substring(0, 255 - ext.length) + ext;

  return filename;
}

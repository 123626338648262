// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";
import { isMongoId } from "validator";
import { z } from "zod";

import KnowledgeDatabaseView from "./view";

import PendingComponent from "../../../../components/PendingComponent";
import {
  getKnowledgeDatabaseModelRecords,
  getKnowledgeDatabaseModels,
} from "../../../../services/knowledge-db";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessKnowledgeDatabase,
  canAccessLists,
  canAccessPuntt,
  requiresAuth,
} from "../../../../utils/auth";
import { isNil } from "../../../../utils/fp";
import { waitForState } from "../../../../utils/hooks";
import { mergeKnowledgeDatabaseState } from "../../../../utils/slices/knowledge-db";
import { store } from "../../../../utils/store";
import { authLayoutRoute } from "../../../auth-layout/route";
import { listsRoute } from "../../../lists/route";
import { myNetworkRoute } from "../../../network/route";
import { ticketsRoute } from "../../../tickets/route";
// TODO: move this to app-wide components
import ErrorComponent from "../../../tickets/routes/ticket/components/ErrorComponent";
import { aboutMeEditRoute } from "../../../userProfile/routes/AboutMe/editRoute";

export const knowledgeDatabaseRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  validateSearch(search: Record<string, unknown>) {
    const schema = z.object({
      tab: z.string().refine(isMongoId).optional(),
      q: z.string().optional(),
      pageSize: z.number().default(100),
    });

    return schema.parse(search);
  },
  path: "settings/knowledge-database",
  loaderDeps({ search }) {
    return search;
  },
  beforeLoad() {
    requiresAuth();

    if (!canAccessKnowledgeDatabase()) {
      if (canAccessPuntt()) {
        throw redirect({
          to: ticketsRoute.to,
        });
      }
      if (canAccessCreativeConnect()) {
        throw redirect({
          to: myNetworkRoute.to,
        });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({
          to: aboutMeEditRoute.to,
        });
      }

      if (canAccessLists()) {
        throw redirect({
          to: listsRoute.to,
        });
      }
    }
  },
  async loader({ context, deps }) {
    const { queryClient } = context;
    const { tab, q, pageSize } = deps;

    const models = await queryClient.ensureQueryData({
      queryKey: ["knowledge-database-models"],
      queryFn: getKnowledgeDatabaseModels,
      staleTime: 1000,
    });

    if (isNil(models) || !models.length) return {};

    // if there was no tab param, push the user to the first available
    if (isNil(tab)) {
      throw redirect({
        to: knowledgeDatabaseRoute.to,
        search: {
          tab: models[0]._id,
          pageSize: 100,
        },
      });
    }

    // otherwise, if there was a tab param, but we didn't find it in the models,
    // return a 404
    const nextTab = models.find((model) => model._id === tab)?._id;

    if (isNil(nextTab)) {
      throw { status: 404 };
    }

    const databaseRecords = await queryClient.fetchQuery({
      queryKey: ["knowledge-database", nextTab, q, pageSize],
      queryFn: () =>
        getKnowledgeDatabaseModelRecords({
          dbModelId: nextTab,
          search: {
            query: q,
            pageSize: String(pageSize),
          },
        }),
    });

    if (isNil(databaseRecords)) {
      throw new Error(`Unable to get records for database ${tab}`);
    }

    store.dispatch(
      mergeKnowledgeDatabaseState({
        records: databaseRecords.dataRecords,
        hasMore: databaseRecords.more,
        models,
        schema: models.find((m) => m._id === tab)?.properties,
        isLoadingMore: false,
      }),
    );

    await waitForState(
      store,
      (state) => !isNil(state.knowledgeDatabase.records),
    );

    return {};
  },
  component: KnowledgeDatabaseView,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  gcTime: 0,
  shouldReload: false,
  pendingMs: 1000,
  pendingMinMs: 500,
});

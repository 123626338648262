import {
  UpdateAIPersonasBody,
  updateAIPersonasResponseSchema,
  getKnowledgeSettingsResponseSchema,
  UpdateKnowledgeSettingsRequest,
  updateBriefGuidelinesResponseSchema,
} from "@mg/schemas/src/christo/catalyst";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getGuidelines() {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledge-settings`,
    {
      method: "GET",
    },
  );

  const parsedData = getKnowledgeSettingsResponseSchema.parse(json);

  return parsedData;
}

export async function updateGuidelines(
  payload: UpdateKnowledgeSettingsRequest["body"],
) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledge-settings`,
    {
      method: "PUT",
      body: JSON.stringify({ ...payload }),
    },
  );

  const parsedData = updateBriefGuidelinesResponseSchema.parse(json);

  return parsedData;
}

export async function updateAIPersona(payload: UpdateAIPersonasBody) {
  const json = await smartFetch(`${API_URL}/private/catalyst/ai-personas`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });

  const parsedData = updateAIPersonasResponseSchema.parse(json);
  return parsedData;
}

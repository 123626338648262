import { UploadSimple } from "@phosphor-icons/react";
import { Button, Flex, Select, Text } from "@radix-ui/themes";
import { Navigate } from "@tanstack/react-router";
import {
  createShapeId,
  type Editor,
  loadSnapshot,
  Tldraw,
} from "@tldraw/tldraw";
import { useEffect, useRef, useState, useLayoutEffect } from "react";

import { TutorialCommentDrawer } from "./TutorialSidebar";

import { HighlightModal } from "../../../components/HighlightModal";
import bottomNavImage from "../../../images/tldraw-toolbar.png";
import TutorialThumb from "../../../images/tutorial-main.png";
import { useAppSelector } from "../../../utils/hooks";
import { assetUrls } from "../../../utils/tldraw/assets";
import { customShapeUtils } from "../../../utils/tldraw/shapeUtils";
import { customTools } from "../../../utils/tldraw/tools";
import { Checkerboard } from "../../tickets/components/Checkerboard";
import { ShareTicketDialog } from "../../tickets/components/ShareTicketDialog";
import { tutorialTicketsRoute } from "../route";

const shapesObject = {
  store: {
    "document:document": {
      gridSize: 10,
      name: "",
      meta: {},
      id: "document:document",
      typeName: "document",
    },
    "page:page": {
      meta: {},
      id: "page:page",
      name: "Page 1",
      index: "a1",
      typeName: "page",
    },
    "asset:1340302615": {
      meta: {},
      id: "asset:1340302615",
      type: "image",
      props: {
        src: TutorialThumb,
        h: 258,
        w: 678,
        name: "/670d5aeb68867a466f8150f1.png",
        isAnimated: false,
        mimeType: "image/png",
      },
      typeName: "asset",
    },
    "shape:oMcHeq1PVyJIUAz-j9VT1": {
      x: 0,
      y: 0,
      rotation: 0,
      isLocked: false,
      opacity: 1,
      meta: {},
      id: "shape:oMcHeq1PVyJIUAz-j9VT1",
      type: "image",
      props: {
        w: 678,
        h: 258,
        assetId: "asset:1340302615",
        playing: true,
        url: "",
        crop: null,
        flipX: false,
        flipY: false,
      },
      parentId: "page:page",
      index: "a1",
      typeName: "shape",
    },
  },
  schema: {
    schemaVersion: 2,
    sequences: {
      "com.tldraw.store": 4,
      "com.tldraw.asset": 1,
      "com.tldraw.camera": 1,
      "com.tldraw.document": 2,
      "com.tldraw.instance": 25,
      "com.tldraw.instance_page_state": 5,
      "com.tldraw.page": 1,
      "com.tldraw.instance_presence": 5,
      "com.tldraw.pointer": 1,
      "com.tldraw.shape": 4,
      "com.tldraw.asset.bookmark": 2,
      "com.tldraw.asset.image": 5,
      "com.tldraw.asset.video": 5,
      "com.tldraw.shape.group": 0,
      "com.tldraw.shape.text": 2,
      "com.tldraw.shape.bookmark": 2,
      "com.tldraw.shape.draw": 2,
      "com.tldraw.shape.geo": 9,
      "com.tldraw.shape.note": 7,
      "com.tldraw.shape.line": 5,
      "com.tldraw.shape.frame": 0,
      "com.tldraw.shape.arrow": 5,
      "com.tldraw.shape.highlight": 1,
      "com.tldraw.shape.embed": 4,
      "com.tldraw.shape.image": 4,
      "com.tldraw.shape.video": 2,
      "com.tldraw.shape.comment": 0,
      "com.tldraw.shape.external": 0,
      "com.tldraw.shape.comment-avatar": 0,
      "com.tldraw.binding.arrow": 0,
    },
  },
};
const aiComments = [
  {
    createdBy: {
      _id: "66a2f569cab5f7530181bb0d",
      name: "AI Reviewer",
    },
    aiPersona: {
      name: "",
      avatar: "",
    },
    description:
      'Headlines should not use title case. Change "Reviews" to be "reviews" to align with brand guidelines.',
    createdAt: "2024-10-14T15:03:18.232Z",
    updatedAt: "2024-10-14T15:03:18.232Z",
    isDeleted: false,
    isPending: false,
    isReview: false,
    votes: [],
    isAI: true,
    reviewId: "670d3278c81cdec5bbc2bad6",
    mentions: [],
    messages: [],
    x: 50,
    y: 30,
    isRequired: true,
    boardType: "revisionBoard",
    boardId: "670d31ad1675809b0ccc867e",
    disposition: "default",
    _id: "670d32b6ff0351b53e442eac",
  },
  {
    createdBy: {
      _id: "66a2f569cab5f7530181bb0d",
      name: "AI Reviewer",
    },
    aiPersona: {
      name: "",
      avatar: "",
    },
    description:
      "The graphic element overlaps the headline text which makes it unreadable. Adjust the flow of the text to avoid the collision with other elements.",
    createdAt: "2024-10-14T15:03:18.232Z",
    updatedAt: "2024-10-14T15:03:18.232Z",
    isDeleted: false,
    isPending: false,
    isReview: false,
    votes: [],
    isAI: true,
    reviewId: "670d3278c81cdec5bbc2bad6",
    mentions: [],
    messages: [],
    x: 150,
    y: 100,
    isRequired: true,
    boardType: "revisionBoard",
    boardId: "670d31ad1675809b0ccc867e",
    disposition: "default",
    _id: "670d32b6ff0351b53e442ead",
  },
  {
    createdBy: {
      _id: "66a2f569cab5f7530181bb0d",
      name: "AI Reviewer",
    },
    aiPersona: {
      name: "",
      avatar: "",
    },
    description:
      "The Puntt logo doesn't have enough contrast against the background. Update to the approved white version or adjust the background gradient for better readability.",
    createdAt: "2024-10-14T15:15:22.332Z",
    updatedAt: "2024-10-14T15:15:22.332Z",
    isDeleted: false,
    isPending: false,
    isReview: false,
    votes: [],
    isAI: true,
    reviewId: "670d3566c81cdec5bbc2bad9",
    mentions: [],
    messages: [],
    x: 365,
    y: 50,
    isRequired: true,
    boardType: "revisionBoard",
    boardId: "670d351c9e0027ce7a09808b",
    disposition: "default",
    _id: "670d358aff0351b53e442f4f",
  },
  {
    createdBy: {
      _id: "66a2f569cab5f7530181bb0d",
      name: "AI Reviewer",
    },
    aiPersona: {
      name: "",
      avatar: "",
    },
    description:
      "Because this image is intended for email, make sure that the file size is less than 1MB but maintains quality.",
    createdAt: "2024-10-14T15:15:22.332Z",
    updatedAt: "2024-10-14T15:15:22.332Z",
    isDeleted: false,
    isPending: false,
    isReview: false,
    votes: [],
    isAI: true,
    reviewId: "670d3566c81cdec5bbc2bad9",
    mentions: [],
    messages: [],
    x: 500,
    y: 120,
    isRequired: false,
    boardType: "revisionBoard",
    boardId: "670d351c9e0027ce7a09808b",
    disposition: "default",
    _id: "670d358aff0351b53e442f50",
  },
  {
    createdBy: {
      _id: "66a2f569cab5f7530181bb0d",
      name: "AI Reviewer",
    },
    aiPersona: {
      name: "",
      avatar: "",
    },
    description:
      "Adjust the content line break to be more clear for the messaging hierarchy.",
    createdAt: "2024-10-14T15:18:42.224Z",
    updatedAt: "2024-10-14T15:18:42.224Z",
    isDeleted: false,
    isPending: false,
    isReview: false,
    votes: [],
    isAI: true,
    reviewId: "670d362fc81cdec5bbc2bada",
    mentions: [],
    messages: [],
    x: 65,
    y: 220,
    isRequired: false,
    boardType: "revisionBoard",
    boardId: "670d351c9e0027ce7a09808b",
    disposition: "default",
    _id: "670d3652ff0351b53e442f7d",
  },
  {
    createdBy: {
      _id: "66a2f569cab5f7530181bb0d",
      name: "AI Reviewer",
    },
    aiPersona: {
      name: "",
      avatar: "",
    },
    description:
      "Consider changing the icon to a different color so it's more cohesive with the rest of the graphic elements.",
    createdAt: "2024-10-14T15:18:42.224Z",
    updatedAt: "2024-10-14T18:00:21.646Z",
    isDeleted: false,
    isPending: false,
    isReview: false,
    votes: [],
    isAI: true,
    reviewId: "670d362fc81cdec5bbc2bada",
    mentions: [],
    messages: [],
    x: 600,
    y: 69,
    isRequired: false,
    boardType: "revisionBoard",
    boardId: "670d351c9e0027ce7a09808b",
    disposition: "dismissed",
    _id: "670d3652ff0351b53e442f7e",
  },
  {
    createdBy: {
      _id: "66a2f569cab5f7530181bb0d",
      name: "AI Reviewer",
    },
    aiPersona: {
      name: "",
      avatar: "",
    },
    description:
      "The section 'Hydration' is followed by detailed text that might be too lengthy for optimal readability. Consider simplifying the content to enhance user engagement and to maintain focus on key information.",
    createdAt: "2024-10-14T15:18:42.224Z",
    updatedAt: "2024-10-14T15:18:42.224Z",
    isDeleted: false,
    isPending: false,
    isReview: false,
    votes: [],
    isAI: true,
    reviewId: "670d362fc81cdec5bbc2bada",
    mentions: [],
    messages: [],
    x: 543,
    y: 672,
    isRequired: false,
    boardType: "revisionBoard",
    boardId: "670d351c9e0027ce7a09808b",
    disposition: "default",
    _id: "670d3652ff0351b53e442f7f",
  },
];
// possibly loader, low lift canvas if possible
export function TutorialRevision() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const [comments, setComments] = useState([]);
  const { tutorialStep } = useAppSelector((state) => state.onboarding);
  const [editor, setEditor] = useState<Editor>();

  useLayoutEffect(() => {
    if (!headerRef.current) return;
    // add one for shadow offset
    setHeaderHeight(headerRef.current.getBoundingClientRect().bottom + 1);
    // I don't know why, but it doesn't work without this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current]);
  useEffect(() => {
    if (editor == null || tutorialStep != 4) {
      return;
    }

    const commentsWithShapeIds = aiComments.map((comment) => {
      // frontend
      // @ts-expect-error TS2339: shapeId is temporal and is not tracked in the
      // original type
      if (comment.shapeId != null) {
        return comment;
      }

      const shapeId = createShapeId();
      const tempComment = { ...comment };
      // frontend
      // @ts-expect-error TS2339: shapeId is temporal and is not tracked in the
      // original type
      tempComment.shapeId = shapeId;

      return tempComment;
    });
    // @ts-expect-error TS2345: shapeId is temporal and is not tracked in the
    // original type
    setComments(commentsWithShapeIds);
    // create the shapes on the board
    editor.run(() => {
      editor.createShapes(
        commentsWithShapeIds.flatMap((comment) => {
          const avatarShapeId = createShapeId();
          return [
            {
              id: avatarShapeId,
              type: "comment-avatar",
              x: comment.x,
              y: comment.y,
              props: {
                h: 25,
                w: 25,
              },
              meta: {
                // @ts-expect-error TS2339: shapeId is temporal and is not tracked in the
                // original type
                linkedCommentId: comment.shapeId,
                comment: JSON.stringify(comment),
              },
            },
          ];
        }),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, tutorialStep]);

  if (tutorialStep < 3) {
    return <Navigate to={tutorialTicketsRoute.to} />;
  }
  return (
    <>
      <header ref={headerRef} className="p-4">
        <Text size="7">Demo Image</Text>

        <div className="mt-2 flex items-center gap-2 text-base-black">
          <Select.Root size="1" value={"0"}>
            <Select.Trigger variant="surface" color="gray" />
            <Select.Content>
              {[{ key: "1", value: "1" }].map((_, i) => (
                <Select.Item key={i} value={i.toString()}>
                  v1
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
          <Text>of 1</Text>
          <HighlightModal
            tooltipPosition={{ top: 50, left: 150 }}
            order={6}
            show
          >
            <Flex gap="2" align="center">
              <Button size="1" onClick={() => {}}>
                Upload New Version <UploadSimple />
              </Button>

              <ShareTicketDialog />
            </Flex>
          </HighlightModal>
        </div>
      </header>

      <TutorialCommentDrawer editor={editor} comments={comments} />
      <div className="fixed -bottom-5 z-[100] flex w-full justify-center pr-80">
        <HighlightModal
          tooltipPosition={{ bottom: 50, right: 250 }}
          order={5}
          show
        >
          <img alt="sample creative project" src={bottomNavImage} />
        </HighlightModal>
      </div>
      <div
        className="fixed inset-0 [&_.tl-shape-error-boundary]:hidden"
        style={{
          top: headerHeight,
          right: 320,
          left: 0,
        }}
      >
        <Tldraw
          initialState="combo"
          assetUrls={assetUrls}
          tools={customTools}
          maxAssetSize={100 * 1024 * 1024}
          components={{
            Grid: (props) => <Checkerboard {...props} mode="canvas" />,
            Spinner: null,
            ContextMenu: null,
          }}
          hideUi
          acceptedImageMimeTypes={[
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
            "image/svg+xml",
            "application/illustrator",
            "application/eps",
            "application/postscript",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/pdf",
            "application/zip",
            "application/*",
            "image/*",
          ]}
          shapeUtils={customShapeUtils}
          onMount={(editor) => {
            setEditor(editor);
            // @ts-expect-error TS2345: TLDraw does not report their own types
            // correctly.
            loadSnapshot(editor.store, shapesObject);
            editor.zoomToFit();
            editor.updateInstanceState({
              isGridMode: true,
            });
          }}
        />
      </div>
    </>
  );
}

import { getKnowledgeDataRecordResponseSchema } from "@mg/schemas/src/christo/catalyst";
import { z } from "zod";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";
import { parseResponse } from "../utils/parseResponse";
import {
  CreateKnowledgeDataModelRequest,
  getKnowledgeDataModelRecordsResponseSchema,
  KnowledgeDataModel,
  knowledgeDataModelSchema,
  KnowledgeDataRecord,
  KnowledgeDataRecordProperty,
  knowledgeDataRecordSchema,
} from "../utils/parsers/knowledge-db";

export async function getKnowledgeDatabaseModels() {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModels`,
    {
      method: "GET",
    },
  );

  const parsedData = parseResponse(z.array(knowledgeDataModelSchema), json);
  return parsedData;
}

export type GetKnowledgeDatabasePayload = {
  dbModelId: string;
  search?: {
    pageSize: string;
    query?: string;
    searchAfter?: string; // ${isoDate}-${objectId}
  };
};

export async function createKnowledgeDataModel(
  payload: CreateKnowledgeDataModelRequest,
) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModel`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
  );

  const parsedData = parseResponse(knowledgeDataModelSchema, json);
  return parsedData;
}

export async function updateKnowledgeDataModel(payload: KnowledgeDataModel) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModel/${payload._id}`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    },
  );

  const parsedData = parseResponse(knowledgeDataModelSchema, json);
  return parsedData;
}

export async function deleteKnowledgeDataModel(modelId: string) {
  await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModel/${modelId}`,
    {
      method: "DELETE",
    },
  );

  return undefined;
}

export async function getKnowledgeDatabaseModelRecords(
  payload: GetKnowledgeDatabasePayload,
) {
  const { dbModelId, search } = payload;
  const filteredSearch = Object.entries(search ?? {}).filter(([, value]) =>
    Boolean(value),
  );
  const searchParams = Object.fromEntries(filteredSearch);
  const queryParams = new URLSearchParams(searchParams);

  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModel/${dbModelId}/knowledgeDataRecords?${queryParams.toString()}`,
    {
      method: "GET",
    },
  );

  const parsedData = parseResponse(
    getKnowledgeDataModelRecordsResponseSchema,
    json,
  );
  return parsedData;
}

export async function getKnowledgeDataRecord(knowledgeDataRecordId: string) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataRecord/${encodeURIComponent(knowledgeDataRecordId)}`,
    {
      method: "GET",
    },
  );

  const parsedData = parseResponse(getKnowledgeDataRecordResponseSchema, json);

  return parsedData;
}

type CreateKnowledgeDataRecordRequest = {
  modelId: string;
  properties: Omit<KnowledgeDataRecordProperty, "_id">[];
};

export async function createKnowledgeDataRecord(
  payload: CreateKnowledgeDataRecordRequest,
) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModel/${payload.modelId}/knowledgeDataRecords`,
    {
      method: "POST",
      body: JSON.stringify(payload.properties),
    },
  );

  const parsedData = parseResponse(knowledgeDataRecordSchema, json);
  return parsedData;
}

type UpdateKnowledgeDataRecordRequest = {
  modelId: string;
  record: KnowledgeDataRecord;
};

export async function updateKnowledgeDataRecord(
  payload: UpdateKnowledgeDataRecordRequest,
) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModel/${payload.modelId}/knowledgeDataRecords/${payload.record._id}`,
    {
      method: "PUT",
      body: JSON.stringify(payload.record),
    },
  );

  const parsedData = parseResponse(knowledgeDataRecordSchema, json);
  return parsedData;
}

type DeleteKnowledgeDataRecordRequest = {
  modelId: string;
  recordId: string;
};

export async function deleteKnowledgeDataRecord(
  payload: DeleteKnowledgeDataRecordRequest,
) {
  await smartFetch(
    `${API_URL}/private/catalyst/knowledgeDataModel/${payload.modelId}/knowledgeDataRecords/${payload.recordId}`,
    {
      method: "DELETE",
    },
  );

  return undefined;
}

import { z } from "zod";

import { enterpriseSchema } from "./enterprise";
import {
  knowledgeDataModel,
  knowledgeDataModelProperty,
} from "./knowledgeDataModel";

export const knowledgeDataRecordPropertySchema = knowledgeDataModelProperty
  .omit({
    isSystem: true,
    required: true,
    type: true,
  })
  .extend({
    value: z.union([z.string(), z.number(), z.boolean()]),
    propertyId: z.string(),
  });

export type KnowledgeDataRecordProperty = z.infer<
  typeof knowledgeDataRecordPropertySchema
>;
export const knowledgeDataRecordSchema = z.object({
  _id: z.string(),
  modelId: z.union([z.string(), knowledgeDataModel]),
  updatedIndex: z.string().optional(),
  enterpriseId: z.union([z.string(), enterpriseSchema]),
  data: z.array(knowledgeDataRecordPropertySchema),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  createdBy: z.string().optional(),
  deletedAt: z.coerce.date().optional(),
});

export const createKnowledgeDataRecordSchema = knowledgeDataRecordSchema
  .omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    updatedIndex: true,
    data: true,
  })
  .extend({
    data: z.array(knowledgeDataRecordPropertySchema.omit({ _id: true })),
  });

export type KnowledgeDataRecord = z.infer<typeof knowledgeDataRecordSchema>;
export type CreateKnowledgeDataRecord = z.infer<
  typeof createKnowledgeDataRecordSchema
>;

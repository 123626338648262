import { useMutation, useQuery } from "@tanstack/react-query";

import { updateAIPersona } from "../../services/brand-guidelines";
import {
  createKnowledgeDataModel,
  createKnowledgeDataRecord,
  deleteKnowledgeDataModel,
  deleteKnowledgeDataRecord,
  getKnowledgeDatabaseModelRecords,
  getKnowledgeDataRecord,
  updateKnowledgeDataModel,
  updateKnowledgeDataRecord,
} from "../../services/knowledge-db";
import { isNil } from "../fp";

export function useGetKnowledgeDataRecord(knowledgeDataRecordId?: string) {
  const query = useQuery({
    queryKey: ["knowledge-data-record", knowledgeDataRecordId],
    queryFn: () => getKnowledgeDataRecord(knowledgeDataRecordId!),
    enabled: !isNil(knowledgeDataRecordId),
  });

  return query;
}

export function useCreateModelMutation() {
  return useMutation({
    mutationKey: ["knowledge-data-model", "create"],
    mutationFn: createKnowledgeDataModel,
  });
}

export function useUpdateModelMutation() {
  return useMutation({
    mutationKey: ["knowledge-data-model", "update"],
    mutationFn: updateKnowledgeDataModel,
  });
}

export function useDeleteModelMutation() {
  return useMutation({
    mutationKey: ["knowledge-data-model", "delete"],
    mutationFn: deleteKnowledgeDataModel,
  });
}

export function useGetKnowledgeDataRecordMutation() {
  return useMutation({
    mutationKey: ["knowledge-data-record"],
    mutationFn: getKnowledgeDatabaseModelRecords,
  });
}

export function useCreateRecordMutation() {
  return useMutation({
    mutationKey: ["knowledge-data-record", "create"],
    mutationFn: createKnowledgeDataRecord,
  });
}

export function useUpdateRecordMutation() {
  return useMutation({
    mutationKey: ["knowledge-data-record", "update"],
    mutationFn: updateKnowledgeDataRecord,
  });
}

export function useDeleteRecordMutation() {
  return useMutation({
    mutationKey: ["knowledge-data-record", "delete"],
    mutationFn: deleteKnowledgeDataRecord,
  });
}

export function useUpdatePersonaMutation() {
  return useMutation({
    mutationKey: ["ai-personas", "update"],
    mutationFn: updateAIPersona,
  });
}

import { Button, Dialog, Flex, Grid } from "@radix-ui/themes";

import { Preview } from "../../../../../components/Preview";
import { isNil } from "../../../../../utils/fp";
import { type GetKnowledgeDataModelRecordsResponse } from "../../../../../utils/parsers/knowledge-db";
import { type BaseDialogProps } from "../../../../../utils/types/dialog";

type DeleteRecordDialogProps = BaseDialogProps & {
  /**
   * The record we are deleting
   */
  record?: GetKnowledgeDataModelRecordsResponse["dataRecords"][number];
  onSubmit(
    record: GetKnowledgeDataModelRecordsResponse["dataRecords"][number],
  ): void;
};

const BASE_TESTID = "delete-record-dialog";

export default function DeleteRecordDialog(props: DeleteRecordDialogProps) {
  const { isSubmitting, open, onOpenChange, onSubmit, record } = props;

  if (isNil(record)) return null;

  return (
    <Dialog.Root
      open={open}
      onOpenChange={onOpenChange}
      data-testid={BASE_TESTID}
    >
      <Dialog.Content
        size="2"
        width="450px"
        data-testid={`${BASE_TESTID}-content`}
      >
        <Dialog.Title>Delete Record</Dialog.Title>

        <Grid gap="3">
          <Dialog.Description>
            Are you sure you want to delete this record?
          </Dialog.Description>

          <Preview knowledgeDataRecord={record.data} />

          <Flex
            gap="2"
            justify="end"
            data-testid={`${BASE_TESTID}-dialog-actions`}
          >
            <Dialog.Close disabled={isSubmitting}>
              <Button
                size="2"
                variant="soft"
                color="gray"
                data-testid={`${BASE_TESTID}-dialog-cancel`}
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              size="2"
              color="red"
              onClick={() => onSubmit(record)}
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid={`${BASE_TESTID}-dialog-submit`}
            >
              Delete
            </Button>
          </Flex>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
}

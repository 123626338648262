import { RevisionBoardErrorReason } from "@mg/schemas/src/commons";
import { useRouter } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";

import AssetCanvas from "./AssetCanvas";

import {
  DOC_FILE_EXTENSIONS,
  VIDEO_EXTENSIONS,
} from "../../../../../../utils/constants";
import { isNil } from "../../../../../../utils/fp";
import { useAppSelector } from "../../../../../../utils/hooks";
import { downloadAsset } from "../../../../../../utils/http";
import {
  useTicketVersionStatusMutation,
  useDownloadTicketLink,
} from "../../../../../../utils/queries/projects";
import { VideoMode } from "../../../../components/VideoPlayback";
import { ticketRoute } from "../../route";
import { useTicket } from "../../view";

const errorReasonToHumanReadable = (reason?: RevisionBoardErrorReason) => {
  switch (reason) {
    case RevisionBoardErrorReason.FILE_TOO_LARGE:
      return "file is over max size of 4GB";
    case RevisionBoardErrorReason.DIMENSIONS_TOO_LARGE:
      return "image is over max dimensions of 100MP";
    case RevisionBoardErrorReason.VIDEO_TOO_LONG:
      return "video is over max length of 2 hours";
    case RevisionBoardErrorReason.TOO_MANY_FILES:
      return "archive contains more than 100 files";
    case RevisionBoardErrorReason.TOO_MANY_PAGES:
      return "document contains more than 250 pages";
    default:
      return "Unknown error";
  }
};

export default function TicketCanvas() {
  const router = useRouter();
  const { ticketId } = ticketRoute.useParams();
  const { videoRef } = useTicket();
  const ticketDownloadMutation = useDownloadTicketLink();

  // Slices
  const reversedVersionIndex = useAppSelector(
    (state) => state.ticket.reversedVersionIndex,
  );
  const versions = useAppSelector((state) => state.ticket.revisions);

  // Queries and mutations
  const ticketVersionStatusMutation = useTicketVersionStatusMutation(ticketId);

  // Local state
  // TODO: move all of this to the slice
  const currentVersion = useMemo(() => {
    return versions[reversedVersionIndex!];
  }, [reversedVersionIndex, versions]);

  const hasExactlyOneReviewFile = useMemo(() => {
    if (isNil(currentVersion?.reviewFiles)) {
      return false;
    }

    return currentVersion.reviewFiles.length === 1;
  }, [currentVersion]);

  function handleDownloadTicket() {
    ticketDownloadMutation.mutate(ticketId, {
      onSuccess(data) {
        if (data) {
          downloadAsset(data.url);
        }
      },
    });
  }

  const viewMode = useMemo(() => {
    if (currentVersion?.errorDetails?.reason) {
      return "error";
    }

    if (hasExactlyOneReviewFile) {
      const firstFile = currentVersion!.reviewFiles![0];
      const { source } = firstFile;
      const fileExtension = source.slice(source.lastIndexOf(".") + 1);

      if (DOC_FILE_EXTENSIONS.includes(fileExtension.toLocaleLowerCase())) {
        return "doc";
      }

      if (VIDEO_EXTENSIONS.includes(fileExtension.toLocaleLowerCase())) {
        return "video";
      }
    }

    return "canvas";
  }, [currentVersion, hasExactlyOneReviewFile]);

  // Effect chain

  useEffect(() => {
    // When uploading a version that results in doc mode, we will have to wait
    // for some amount of processing to be done on the API. Because of this, we
    // will poll the status at some interval (3 seconds currently).
    if (
      (!currentVersion?.isPending && currentVersion?.isAIReady) ||
      isNil(currentVersion)
    ) {
      return;
    }

    const interval = setInterval(() => {
      ticketVersionStatusMutation.mutate(currentVersion._id, {
        onSuccess(data) {
          if (isNil(data)) return;

          if (
            data.isPending !== currentVersion.isPending ||
            data.isAIReady !== currentVersion.isAIReady
          ) {
            router.invalidate();
          }
        },
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [currentVersion]);

  /////
  // No hooks beyond this point
  /////

  function renderDocument() {
    switch (viewMode) {
      case "video":
        // TODO: improve VideoMode to not use a ref. The component should be
        // provided functionality from the parent component pertaining to
        // comment clicks, etc. It should otherwise not try to be too smart
        // since that can lead to unnecessary bugs when things are happening
        // across many different components when comments are added.
        return <VideoMode ref={videoRef} />;
      case "doc":
      case "canvas":
        return <AssetCanvas mode={viewMode} />;
      case "error":
        return (
          <div className="col-span-full grid h-full place-items-center">
            <div className="text-center">
              <h3 className="p-5 text-2xl">
                Sorry, this file cannot be displayed.
              </h3>
              <p>
                It failed to process with the error:{" "}
                <em>
                  {errorReasonToHumanReadable(
                    currentVersion?.errorDetails?.reason,
                  )}
                </em>
                .
              </p>
              <p>
                You can{" "}
                <button
                  onClick={handleDownloadTicket}
                  className="rt-BaseButton rt-Button cursor-pointer rounded border-none font-normal text-puntt-blue-10 transition-colors hover:text-puntt-blue-11 active:text-puntt-blue-9"
                >
                  download the file
                </button>{" "}
                instead.
              </p>
            </div>
          </div>
        );
      default:
        throw new Error("Indeterminate document mode.");
    }
  }

  return renderDocument();
}

import { Grid, Spinner, Text } from "@radix-ui/themes";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { isNil } from "../../../../../utils/fp";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import { useGetKnowledgeDataRecordMutation } from "../../../../../utils/queries/knowledge-db";
import {
  mergeKnowledgeDatabaseState,
  setIsLoadingMore,
} from "../../../../../utils/slices/knowledge-db";
import { knowledgeDatabaseRoute } from "../route";

type InfiniteScrollTriggerProps = {
  hasMore: boolean;
  searchAfter?: string;
};

export function InfiniteScrollTrigger(props: InfiniteScrollTriggerProps) {
  const { hasMore, searchAfter } = props;
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { pageSize, tab } = knowledgeDatabaseRoute.useSearch();
  const dispatch = useAppDispatch();
  // TODO: I want to make this component reusable and I don't want to have to do
  // ad-hoc logic like this.
  const records = useAppSelector((state) => state.knowledgeDatabase.records);
  const getKnowledgeDatabaseMutation = useGetKnowledgeDataRecordMutation();

  useEffect(() => {
    if (inView && hasMore && !getKnowledgeDatabaseMutation.isPending) {
      dispatch(setIsLoadingMore(true));
      getKnowledgeDatabaseMutation.mutate(
        {
          dbModelId: tab!,
          search: {
            pageSize: String(pageSize),
            searchAfter,
          },
        },
        {
          onSuccess(data) {
            if (isNil(data)) return;

            const { more: hasMore, dataRecords } = data;
            const newRecords = [...records, ...dataRecords];

            dispatch(
              mergeKnowledgeDatabaseState({
                hasMore,
                records: newRecords,
                isLoadingMore: false,
              }),
            );
          },
        },
      );
    }
  }, [inView, hasMore, searchAfter, getKnowledgeDatabaseMutation.isPending]);

  if (!hasMore) return null;

  return (
    <Grid ref={ref} py="4" gap="3" maxWidth="max-content" mx="auto">
      <Spinner mx="auto" />

      <Text>Loading More</Text>
    </Grid>
  );
}

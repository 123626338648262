import { z } from "zod";

import { enterpriseSchema } from "./enterprise";

export enum KnowledgeDataModelBaseTypes {
  CLAIMS = "claims",
}

export enum KnowledgeDataModelPropertyType {
  TEXT = "text",
  NUMBER = "number",
  CHECKBOX = "checkbox",
  DATE = "date",
  URL = "url",
  FILE = "file",
}
const timeStamps = z.object({
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});
export const knowledgeDataModelProperty = z.object({
  _id: z.string(),
  name: z.string().min(1),
  type: z.nativeEnum(KnowledgeDataModelPropertyType),
  isSystem: z.boolean(),
  required: z.boolean(),
});

export const knowledgeDataModel = z
  .object({
    _id: z.string(),
    enterpriseId: z.union([z.string(), enterpriseSchema]),
    name: z.string(),
    properties: z.array(knowledgeDataModelProperty),
    isSystem: z.boolean(),
    deletedAt: z.coerce.date().optional(),
  })
  .merge(timeStamps);

export const createKnowledgeDataModelRequestSchema = knowledgeDataModel
  .omit({
    _id: true,
    createdAt: true,
    updatedAt: true,
    properties: true,
    enterpriseId: true,
    deletedAt: true,
  })
  .extend({
    properties: z.array(knowledgeDataModelProperty.omit({ _id: true })),
  });
export type CreateKnowledgeDataModelRequest = z.infer<
  typeof createKnowledgeDataModelRequestSchema
>;

export const optionalKnowledgeDataModelProperty = knowledgeDataModelProperty
  .partial()
  .omit({
    isSystem: true,
  });

export const optionalKnowledgeDataModel = knowledgeDataModel
  .partial()
  .omit({
    enterpriseId: true,
  })
  .extend({
    properties: z.array(optionalKnowledgeDataModelProperty).optional(),
  });

export type KnowledgeDataModel = z.infer<typeof knowledgeDataModel>;
export type KnowledgeDataModelForUpdate = z.infer<
  typeof optionalKnowledgeDataModel
>;

export type KnowledgeDataModelProperty = z.infer<
  typeof knowledgeDataModelProperty
>;

export type KnowledgeDataModelPropertyForUpdate = z.infer<
  typeof optionalKnowledgeDataModelProperty
>;

import { type ReviewFiles } from "@mg/schemas/src/commons";
import {
  AssetRecordType,
  type Editor,
  getHashForString,
  MediaHelpers,
  type StoreSnapshot,
  type TLAsset,
  type TLRecord,
  type TLShapePartial,
  type TLImageShape,
  type TLShape,
  Box,
  type TLStore,
  TLStoreSnapshot,
  createShapeId,
} from "tldraw";

import { loadPdf } from "./pdfs";

import { uploadImageAsset, uploadToS3 } from "../../services/upload";
import { IMAGE_EXTENSIONS } from "../constants";
import { assetForUser } from "../imageHandler";
import { setLoading } from "../slices/ticket";
import { store } from "../store";

type FileType = "file";
type FileInput = {
  type: FileType;
  file: File;
};

async function isGifAnimated(file: Blob): Promise<boolean> {
  const arrayBuffer = await file.arrayBuffer();
  const byteArray = new Uint8Array(arrayBuffer);
  let isAnimated = false;
  let i = 0;

  // GIF frame signature
  const FRAME_SIGNATURE = [0x21, 0xf9, 0x04];

  while (i < byteArray.length - 3) {
    if (
      byteArray[i] === FRAME_SIGNATURE[0] &&
      byteArray[i + 1] === FRAME_SIGNATURE[1] &&
      byteArray[i + 2] === FRAME_SIGNATURE[2]
    ) {
      isAnimated = true;
      break;
    }
    i++;
  }

  return isAnimated;
}

export function externalAssetHandler(editor: Editor) {
  return async function (fileInput: FileInput) {
    store.dispatch(setLoading(true));
    const { file } = fileInput;
    const payload = (await uploadImageAsset(file, true)) as FormData;
    const url = payload?.get("url") as string;
    const key = payload?.get("key") as string;
    payload?.delete("url");

    await uploadToS3({ url, payload });
    const originalUrl = `https://static.puntt.ai/${key}`;

    // Commit the upload to the canvas
    if (file.type.includes("image")) {
      const fileUrl = assetForUser(key) as string;
      const assetId = AssetRecordType.createId(
        getHashForString(fileUrl as string),
      );
      const size = await MediaHelpers.getImageSize(file);

      const asset = AssetRecordType.create({
        id: assetId,
        type: "image",
        typeName: "asset",
        props: {
          name: file.name,
          // TODO: downloading doesn't work yet and we need
          // to add that functionality in.
          src: originalUrl,
          ...size,
          mimeType: file.type,
          isAnimated: file.type === "image/gif" && (await isGifAnimated(file)),
        },
        meta: {
          url: `${url}/${key}`,
        },
      });

      store.dispatch(setLoading(false));

      return asset;
    }

    const shapeHeight = 250;
    const shapeWidth = 300;
    if (file.type.includes("pdf")) {
      await createAssetsFromPdf(file, editor);

      store.dispatch(setLoading(false));
    } else {
      editor.createShape({
        type: "external",
        meta: {
          label: file.name,
          w: shapeWidth,
          h: shapeHeight,
          href: originalUrl,
        },
        x: (window.innerWidth - shapeWidth) / 2,
        y: (window.innerHeight - shapeHeight) / 2,
      });

      store.dispatch(setLoading(false));

      return null;
    }
  };
}

export const filterCommentsFromStore = (data: StoreSnapshot<TLRecord>) => {
  const filteredStore = Object.entries(data.store)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([key, value]) => (value as TLShape).type !== "comment")
    .reduce((acc: { [key: string]: TLRecord }, [key, value]) => {
      acc[key] = value as TLRecord;
      return acc;
    }, {});

  return {
    ...data,
    store: filteredStore,
  };
};

export function escapeRegex(s: string): string {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}
export const createAssetsFromPdf = async (file: File, editor: Editor) => {
  const pdf = await loadPdf(file.name, await file.arrayBuffer());

  editor.createAssets(
    pdf.pages.map((page) => ({
      id: page.assetId,
      typeName: "asset",
      type: "image",
      meta: {},
      props: {
        w: page.bounds.w,
        h: page.bounds.h,
        mimeType: "image/png",
        src: page.src,
        name: "page",
        isAnimated: false,
      },
    })),
  );
  editor.createShapes(
    pdf.pages.map(
      (page): TLShapePartial<TLImageShape> => ({
        id: page.shapeId,
        type: "image",
        x: page.bounds.x,
        y: page.bounds.y,
        props: {
          assetId: page.assetId,
          w: page.bounds.w,
          h: page.bounds.h,
        },
        meta: {
          url: page.src,
        },
      }),
    ),
  );
  editor.groupShapes(pdf.pages.map((page) => page.shapeId));
};

export function updateCameraBounds(targetBounds: Box, editor: Editor) {
  const isMobile = editor.getViewportScreenBounds().width < 840;
  editor.setCameraOptions({
    constraints: {
      bounds: targetBounds,
      padding: { x: isMobile ? 16 : 164, y: 100 },
      origin: { x: 0.5, y: 0 },
      initialZoom: "fit-x",
      baseZoom: "default",
      behavior: "contain",
    },
  });

  editor.setCamera(editor.getCamera(), { reset: true });
}

// Function to calculate the union of bounds of an array of shapes
export function getShapesBounds(shapes: TLImageShape[]): Box {
  if (shapes.length === 0) {
    return new Box(0, 0, 0, 0);
  }

  const initialBounds = new Box(
    shapes[0].x,
    shapes[0].y,
    shapes[0].props.w,
    shapes[0].props.h,
  );

  const targetBounds = shapes.reduce((acc, shape) => {
    const shapeBounds = new Box(shape.x, shape.y, shape.props.w, shape.props.h);
    return acc.union(shapeBounds);
  }, initialBounds.clone());

  return targetBounds;
}

export function getFilteredImageShapes(
  shapes: TLStore | TLShape[],
): TLImageShape[] {
  if (Array.isArray(shapes)) {
    return shapes.filter(
      (shape) =>
        (shape as TLShape).typeName == "shape" &&
        (shape as TLShape).type == "image",
    ) as TLImageShape[];
  }
  return Object.values(shapes).filter(
    (shape) =>
      (shape as TLShape).typeName == "shape" &&
      (shape as TLShape).type == "image",
  );
}

export function filterAndSortShapes(shapes: TLImageShape[]): TLImageShape[] {
  // Filter to keep only one shape per unique meta.url and y + props.h combination
  const uniqueShapes = Object.values(
    shapes.reduce(
      (acc, shape) => {
        const key = `${shape.y}-${shape.props.h}`;
        acc[key] = shape;
        return acc;
      },
      {} as { [key: string]: TLImageShape },
    ),
  );

  // Sort by the y value from lowest to highest
  uniqueShapes.sort((a, b) => a.y - b.y);

  return uniqueShapes;
}

/**
 * Given a serialized TLDraw store and an array of thumbnail URL strings, maps
 * over each shape (as long as it is an image shape), and returns each thumbnail
 * with the shape ID. Useful for handling click events on thumbnails where we
 * know we want to either scroll to a shape or "click" a shape on the canvas.
 *
 * This function exists because we want the order of the thumbnails to respect
 * the order of the shapes themselves. Even though they may be ordered correctly
 * most of the time, this is an assumption we can't make or keep.
 *
 * Assumptions:
 *   - We are in doc mode at this point.
 *   - Each URL has "page_{n}_{width}x{height}", either in thumbnails or in
 *     shape.meta.url
 *   - We know that the thumbnails are generated using the original image file
 *     and we only add "_tile_{n}" at the end before the extension.
 */
export function mapShapesToThumbnails(
  shapes: TLStoreSnapshot,
  thumbnails: string[],
) {
  // @ts-expect-error TS2345: FIXME - this is technically the correct type.
  const imageShapes = getFilteredImageShapes(shapes.store);
  const sortedShapes = filterAndSortShapes(imageShapes);

  return sortedShapes.map((shape) => ({
    shapeId: shape.id,
    thumbnail: thumbnails.find((t) => {
      const shapeUrl = shape.meta?.url;
      if (!shapeUrl) return false;

      const shapeMatch = (shapeUrl as string).match(/page_\d+/);

      if (!shapeMatch) return false;

      const thumbMatch = t.includes(shapeMatch[0]);

      if (!thumbMatch) return false;

      return true;
    }),
  }));
}

/**
 * In instances where we need to initialize the canvas shapes (when the user is
 * in canvas mode), we need to create the assets and images in TLDraw.
 */
export function initializeCanvas(editor: Editor, assets: ReviewFiles[]) {
  if (!assets.length) {
    return editor.getSnapshot().document;
  }

  editor.run(
    () => {
      // Clear history to prevent undo/redo operations from previous states
      editor.clearHistory();

      // First, we want to clear the contents of the editor since we reuse the
      // same instance across multiple versions. This guarantees that assets
      // from other versions do not carry over to the version we are attempting
      // to initialize.

      const existingAssets = editor.getAssets().map((a) => a.id);
      const existingShapes = Array.from(editor.getCurrentPageShapeIds());
      editor.deleteAssets(existingAssets);
      editor.deleteShapes(existingShapes);

      // Next, we want to create our new assets and shapes.
      const createdAssets: TLAsset[] = [];
      const createdShapes: TLShapePartial[] = [];

      assets.forEach((asset) => {
        const shapeId = createShapeId();
        const assetId = AssetRecordType.createId(
          getHashForString(asset.source),
        );

        const fileExtension = asset.source
          .slice(asset.source.lastIndexOf(".") + 1)
          .toLowerCase();

        // If we're dealing with image files.
        if (IMAGE_EXTENSIONS.includes(fileExtension)) {
          const isGif = fileExtension === "gif";

          createdAssets.push(
            AssetRecordType.create({
              id: assetId,
              type: "image",
              props: {
                src: asset.source,
                h: asset.height!,
                w: asset.width!,
                name:
                  asset.filename ??
                  asset.source.slice(asset.source.lastIndexOf("/") + 1),
                isAnimated: isGif,
                mimeType: `image/${fileExtension}`,
              },
            }),
          );

          createdShapes.push({
            id: shapeId,
            type: "image",
            x: asset.x ?? 0,
            y: asset.y ?? 0,
            props: {
              assetId,
              h: asset.height!,
              w: asset.width!,
            },
          });
        }

        // Otherwise, we're dealing with non-image files
        else {
          createdShapes.push({
            id: shapeId,
            type: "external",
            x: asset.x ?? 0,
            y: asset.y ?? 0,
            meta: {
              file: {
                name: asset.source.slice(asset.source.lastIndexOf("/") + 1),
              },
              href: asset.source,
              w: 300,
              h: 60,
            },
          });
        }
      });

      editor.createAssets(createdAssets);
      editor.createShapes(createdShapes);
      editor.zoomToFit();
    },
    { history: "ignore", ignoreShapeLock: true },
  );

  const { document } = editor.getSnapshot();

  return document;
}

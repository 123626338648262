import { Button, Dialog, Flex } from "@radix-ui/themes";

import { useAppSelector } from "../../../utils/hooks";

type DeleteTicketDialogProps = {
  folderId?: string;
  onDelete(ticketId: string): void;
  onCancel(): void;
  isPending: boolean;
};

export function DeleteFolderDialog(props: DeleteTicketDialogProps) {
  const { isPending, onCancel, onDelete, folderId } = props;
  const folders = useAppSelector((state) => state.punttProjects.folders);
  const currentFolderBreadcrumb = useAppSelector(
    (state) => state.punttProjects.breadcrumbs,
  ).at(-1);

  const found =
    currentFolderBreadcrumb?._id === folderId
      ? currentFolderBreadcrumb
      : folders.find((f) => f._id === folderId);

  return (
    <Dialog.Root open={folderId != null}>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Delete folder?</Dialog.Title>
        <Dialog.Description>
          Are you sure you want to delete <em>{found?.name}</em>?
        </Dialog.Description>

        <Flex gap="2" mt="3" justify="end">
          <Button variant="soft" color="gray" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => onDelete(folderId as string)}
            disabled={isPending}
            loading={isPending}
          >
            Delete
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
